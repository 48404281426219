// CookieBanner.jsx
import React, { useState, useEffect } from 'react';
import './CookieBanner.css'; // Import the CSS file for styling

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        console.log("CookieBanner is rendering");
        const isBannerClosed = localStorage.getItem('cookie_banner_closed');
        if (!isBannerClosed) {
            setShowBanner(true);
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem('cookie_banner_closed', 'true');
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <div className="cookie-banner">
            <div className="cookie-banner-content">
                <p className="cookie-banner-text">
                    We use cookies to enhance your browsing experience and provide additional functionality. By continuing to use this site, you consent to our use of cookies. For more details, please read our <a href="/privacy" className="cookie-banner-link">Cookie Policy</a>.
                </p>
                <button onClick={handleClose} className="cookie-banner-button">
                    Close
                </button>
            </div>
        </div>
    );
};

export default CookieBanner;
