import React from "react";
import { Helmet } from "react-helmet";
import "../assets/css/banner-coreDealer.css";
import "../assets/css/homeBanner.css";

const DealerHowItsWork = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          How it Works – Finance That – We are an automotive and powersports
          marketplace that help buyers with instant financing online.
        </title>
        <meta
          name="description"
          content="Choose a vehicle from our marketplace, apply for financing, get instant approval and enjoy your new vehicle. It’s that simple."
        />
      </Helmet>

      {/* banner section */}
      <section className="heroBannerWrapper banner-coreDealer">
        <div className="row">
          <div className="col-md-6">
            <div className="bannerDescriptionWrapper">
              <h1>
                Moving Inventory
                <br />
                Just Got Easier!
              </h1>
              <p>
                For the past several years, we have worked diligently with some
                of Canada's top dealerships and financial lenders to research
                and develop an automotive industry sales and financing solution
                with ground-breaking potential.
                <br />
                <br />
                <strong>Here's what we found:</strong> by directly connecting
                dealers to buyers and providing on-the-spot financing solutions,
                we help make the buying process more stream-lined and efficient
                than ever before.
              </p>
              <div className="row bannerBtn-GoogleReview">
                <div className="col-sm-5">
                  <div className="btnWrapper">
                    <a href="/dealer-sign-up" className="fs__btn">
                      Dealer Sign Up
                      <span className="icon__arrow">
                        <img
                          src="assets/image/applyImgs/icon-long-arrow-white.svg"
                          alt=""
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6" />
          <div className="bannerImg-Wrapper">
            <img
              src="assets/image/applyImgs/core-banner-right-image.png"
              alt=""
            />
          </div>
        </div>
      </section>
      {/*/ banner section */}
      <section className="solutionsBar">
        <div className="innerSolution">
          <span className="number">2</span>
          <h3>Solutions to get you started on our platform:</h3>
        </div>
      </section>
      {/* step with right video */}
      <section className="container-Section signUp-core-section">
        <div className="header-with-circle">
          <h2>
            Sign up for
            <br />
            Core Solution
          </h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="qualifiedStepsMainWrap">
              <h2>
                Find qualified buyers
                <br />
                in 3 easy steps:
              </h2>
              <ul className="qualifiedStepsWrap">
                <li>
                  <h3>Register with Finance That</h3>
                  <p>
                    <a href="/dealer-sign-up">Click here</a> to sign up and create your free
                    dealer account.
                  </p>
                </li>
                <li>
                  <h3>Set your application preferences</h3>
                  <p>
                    Filter through qualified buyers by using our customizable
                    search tool. Filter by Location, Asset Type and Credit
                    Score!{" "}
                  </p>
                </li>
                <li>
                  <h3>Sit back and relax</h3>
                  <p>
                    We send qualified buyers straight to you. Moving your
                    inventory literally couldn’t be any easier!
                  </p>
                </li>
              </ul>
              <div className="btnWrapper">
                <a href="/dealer-sign-up" className="fs__btn btn">
                  Dealer Sign Up
                  <span className="icon__arrow">
                    <img
                      src="assets/image/applyImgs/icon-long-arrow-white.svg"
                      alt=""
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="videoAreaRight">
              <iframe
                width={560}
                height={315}
                src="https://www.youtube.com/embed/3oyZ7Tv2Sds"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </section>
      {/* step with right video */}
      {/* step with left video */}
      <section className="container-Section signUp-core-section comprehensiveSignUp">
        <div className="row">
          <div className="col-md-5 offset-md-7">
            <div className="header-with-circle">
              <h2>
                Sign up for
                <br />
                Comprehensive Solution
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <div className="qualifiedStepsMainWrap">
              <h2>
                Get marketing and buyers
                <br />
                in 3 easy steps:
              </h2>
              <ul className="qualifiedStepsWrap">
                <li>
                  <h3>Register with Finance That</h3>
                  <p>
                    <a href="/dealer-sign-up">Click here</a> to sign up and create your free
                    dealer account.
                  </p>
                </li>
                <li>
                  <h3>List your inventory</h3>
                  <p>
                    Finance That will list your inventory on your Dealership’s
                    own proprietary online marketplace. We then drive paid
                    traffic to your listings through multiple avenues, including
                    Facebook and Google Ads.
                  </p>
                </li>
                <li>
                  <h3>Sit back and relax</h3>
                  <p>
                    With competitive interest rates and a 90% 
                    approval rate, our full-service solution offers your 
                    clients a fast, streamlined pathway to purchasing 
                    your inventory instantly.
                  </p>
                </li>
              </ul>
              <div className="btnWrapper">
                <a href="/dealer-sign-up" className="fs__btn btn">
                  Dealer Sign Up
                  <span className="icon__arrow">
                    <img
                      src="assets/image/applyImgs/icon-long-arrow-white.svg"
                      alt=""
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 order-md-first">
            <div className="videoAreaRight">
              <iframe
                width={560}
                height={315}
                src="https://www.youtube.com/embed/CjqtevHWMbg"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </section>
      {/* step with left video */}
    </React.Fragment>
  );
};
export default DealerHowItsWork;
