/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { history } from "../../../../_helpers";
import { Helmet } from "react-helmet";
import Persona from "persona";
import { useDispatch } from "react-redux";
import { submit_persona_inquery } from "../../../../actions/postDetailActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function VerifyIdentity({ onContinue, reducerState }) {
  const [options, setOptions] = useState({
    templateId: "itmpl_rgg1KCoVs64HqqAeUZtaePn2"
  });
  const path = useLocation().pathname;
  let appid = path.split("/")[2];

  const personaEnvironmentId = process.env.REACT_APP_PERSONA_ENVIRONMENT_ID
  
  // state
  const [isJumioCompleted, setIsJumioCompleted] = useState(false);
  const dispatch = useDispatch();

  // main return
  return (
    <div className="formWrapper verification">
      <Helmet>
        <script src="https://cdn.withpersona.com/dist/persona-v4.8.0.js"></script>
      </Helmet>
      <h1>Confirm your Identity</h1>
      <h3>
        We need to verify your identity to accelerate your financing application
      </h3>
      <div className="centerFlex jumioIframe" id="persona-widget-7de0paals8tf3ls5">
        <Persona.Inquiry
            height="440px"
            width="100%"
            environmentId={personaEnvironmentId}
            templateId= "itmpl_rgg1KCoVs64HqqAeUZtaePn2"
            onLoad={(error) => {
              if (error) {
                console.error(
                  `Failed with code: ${error.code} and message ${error.message}`
                );
              } else {
                console.log("Loaded inline");
              }
            }}
            onStart={(inquiryId) => {
              console.log(`Started inline inquiry ${inquiryId}`);
            }}
            onComplete={(inquiryId) => {
              console.log(
                `Sending finished inline inquiry ${inquiryId} to backend`
              );
              setIsJumioCompleted(true);
              dispatch(submit_persona_inquery({
                "application_id":appid,
                "inquiry_id":inquiryId['inquiryId']
                })
              )
            }}
            onEvent={(name, meta) => {
              switch (name) {
                case "start":
                  console.log(`Received inline event: start`);
                  break;
                default:
                  console.log(
                    `Received inline event: ${name} with meta: ${JSON.stringify(
                      meta
                    )}`
                  );
              }
            }}
          />
      </div>
      <div className="skipSec">
        <button
          onClick={() => onContinue()}
          disabled={!isJumioCompleted}
          className="primaryButton continueBtn"
        >
          <span></span> <span>Continue</span>
          <img src="/assets/image/arrow_circle_right_outline.svg" alt="icon" />
        </button>
        <button
          // onClick={() => history.push("/SubmitSuccess")}
          onClick={() => onContinue()}
          className="skipButton"
        >{`Skip >`}</button>
      </div>
      <p>We need this information to confirm your identity</p>
    </div>
  );
}
