export const LOADING_DEALER_APPLICATIONS = "LOADING_DEALER_APPLICATIONS"
export const GET_PENDING_ACTIVE_APPLICATIONS_DEALER = "GET_PENDING_ACTIVE_APPLICATIONS_DEALER"
export const SINGLE_CHECK_APPLICATION_DEALER = "SINGLE_CHECK_APPLICATION_DEALER"
export const TOGGLE_ALL_CHECK_APPLICATION_DEALER = "TOGGLE_ALL_CHECK_APPLICATION_DEALER"
export const REMOVE_ALL_STATE_APPLICATION_DEALER = "REMOVE_ALL_STATE_APPLICATION_DEALER"
export const UPDATE_APPLICATION_ROW_DATA_DEALER = "UPDATE_APPLICATION_ROW_DATA_DEALER"
export const UPDATE_MANUAL_APPLICATION_ROW_DATA_DEALER = "UPDATE_MANUAL_APPLICATION_ROW_DATA_DEALER"
export const GET_ADDTIONAL_TYPE_DEALER = "GET_ADDTIONAL_TYPE_DEALER"
///
export const ADD_CARD_BILLING = "ADD_CARD_BILLING"
export const BILLING_LOADING = "BILLING_LOADING"
export const BILLING_INVOICES = "BILLING_INVOICES"
///
export const GET_VEHICLE_MAKES_EDIT_POST_APP_DEALER = "GET_VEHICLE_MAKES_EDIT_POST_APP_DEALER"
export const TOGGLE_VEHICLE_MAKE_EDIT_POST_APP_DEALER = "TOGGLE_VEHICLE_MAKE_EDIT_POST_APP_DEALER"
export const GET_VEHICLE_MODEL_EDIT_POST_APP_DEALER = "GET_VEHICLE_MODEL_EDIT_POST_APP_DEALER"
export const TOGGLE_VEHICLE_MODEL_EDIT_POST_APP_DEALER = "TOGGLE_VEHICLE_MODEL_EDIT_POST_APP_DEALER"
export const TOGGLE_VEHICLE_TRIMS_EDIT_POST_APP_DEALER = "TOGGLE_VEHICLE_TRIMS_EDIT_POST_APP_DEALER"
export const GET_VEHICLE_TRIMS_EDIT_POST_APP_DEALER = "GET_VEHICLE_TRIMS_EDIT_POST_APP_DEALER"
export const EDIT_POST_APP_LOADING_DEALER = "EDIT_POST_APP_LOADING_DEALER"
export const EDIT_POST_APP_UPDATE_DEALER = "EDIT_POST_APP_UPDATE_DEALER"
export const REMOVE_UPDATE_ROW_APPLICATION_DEALER = "REMOVE_UPDATE_ROW_APPLICATION_DEALER"
export const DELETE_ADDTIONAL_INFO_LOADING_DEALER = "DELETE_ADDTIONAL_INFO_LOADING_DEALER"
export const DELETE_ADDTIONAL_INFO_DEALER = "DELETE_ADDTIONAL_INFO_DEALER"
export const LOADING_DEALER_APIS = "LOADING_DEALER_APIS"
export const DELETE_VEHICLE_DETAIL_DEALER = "DELETE_VEHICLE_DETAIL_DEALER"
export const DELETE_VEHICLE_DETAIL_LOADING_DEALER = "DELETE_VEHICLE_DETAIL_LOADING_DEALER"
export const DELETE_UPLOADED_DOC_DEALER = "DELETE_UPLOADED_DOC_DEALER"
export const DELETE_UPLOAD_DOC_LOADING_DEALER = "DELETE_UPLOAD_DOC_LOADING_DEALER"
export const FLINKS_REQUEST_ID_RESPONSE_DEALER = "FLINKS_REQUEST_ID_RESPONSE_DEALER"
export const LOADING_FLINKS_REQUEST_ID_DEALER = "LOADING_FLINKS_REQUEST_ID_DEALER"
export const LOADING_FLINKS_LOGIN_ID_DEALER = "LOADING_FLINKS_LOGIN_ID_DEALER"
export const FLINKS_LOGIN_ID_RESPONSE_DEALER = "FLINKS_LOGIN_ID_RESPONSE_DEALER"
export const DELETE_SINGLE_APPLICATION_DEALER = "DELETE_SINGLE_APPLICATION_DEALER"
export const LOADING_DELETE_SINGLE_APPLICATION_DEALER = "LOADING_DELETE_SINGLE_APPLICATION_DEALER"
export const DELETE_MULTIPLE_APPLICATION_DEALER = "DELETE_MULTIPLE_APPLICATION_DEALER"
export const GET_CREDIT_REPORT_DATA_DEALER = "GET_CREDIT_REPORT_DATA_DEALER"
export const LOADING_DEALER_EDIT_POST_STOCK_DETAIL = "LOADING_DEALER_EDIT_POST_STOCK_DETAIL"
export const DEALER_EDIT_POST_STOCK_DETAIL = "DEALER_EDIT_POST_STOCK_DETAIL"
export const DEALER_DELETE_COAPPLICANT_INFO = "DEALER_DELETE_COAPPLICANT_INFO"
export const DEALER_DELETE_COAPPLICANT_LOADING = "DEALER_DELETE_COAPPLICANT_LOADING"
export const UPDATE_APPLICATION_STATUSES_DEALER = "UPDATE_APPLICATION_STATUSES_DEALER"
export const GET_CO_APPLICANT_CREDIT_REPORT_DATA_DEALER = "GET_CO_APPLICANT_CREDIT_REPORT_DATA_DEALER"
export const DEALER_JUMIO_LOADING = "DEALER_JUMIO_LOADING"
export const DEALER_JUMIO_INFO = "DEALER_JUMIO_INFO"
export const GET_AGENTS_APPLICATON_DEALER = "GET_AGENTS_APPLICATON_DEALER"
export const EDIT_DEALER_POST_APP_UPDATE_UPLOAD_DOC = "EDIT_DEALER_POST_APP_UPDATE_UPLOAD_DOC"
export const DELETE_LOAN_DOC_DEALER = "DELETE_LOAN_DOC_DEALER"
export const GET_PDF_DATA_DEALER = "GET_PDF_DATA_DEALER"
export const TOGGLE_VEHICLE_TYPE_DEALER = "TOGGLE_VEHICLE_TYPE_DEALER"
export const GET_TYPE_VEHICLES_DEALER = "GET_TYPE_VEHICLES_DEALER"
/**
 * 
 * 
 * Listing
 */
export const DEALER_LOADING_LISTING = "DEALER_LOADING_LISTING"
export const GET_LISTING_DEALER = "GET_LISTING_DEALER"
export const SINGLE_CHECK_LISTING_DEALER = "SINGLE_CHECK_LISTING_DEALER"
export const TOGGLE_ALL_CHECK_LISTING_DEALER = "TOGGLE_ALL_CHECK_LISTING_DEALER"
export const REMOVE_ALL_STATE_LISTING_DEALER = "REMOVE_ALL_STATE_LISTING_DEALER"
export const GET_LISTING_DETAIL_DEALER = "GET_LISTING_DETAIL_DEALER"
export const LOADING_LISTING_DETAIL_DEALER = "LOADING_LISTING_DETAIL_DEALER"
export const REMOVE_DETAIL_EDIT_DEALER = "REMOVE_DETAIL_EDIT_DEALER"
export const TOGGLE_VEHICLE_MODEL = "TOGGLE_VEHICLE_MODEL"
export const GET_VEHICLE_MODEL = "GET_VEHICLE_MODEL"
export const TOGGLE_VEHICLE_TYPE = "TOGGLE_VEHICLE_TYPE"
export const GET_TYPE_VEHICLES = "GET_TYPE_VEHICLES"
export const TOGGLE_VEHICLE_MAKE = "TOGGLE_VEHICLE_MAKE"
export const GET_VEHICLE_MAKES = "GET_VEHICLE_MAKES"
export const TOGGLE_VEHICLE_TRIMS = "TOGGLE_VEHICLE_TRIMS"
export const GET_VEHICLE_TRIMS = "GET_VEHICLE_TRIMS"
export const GET_VEHICLE_BODY_TYPE = "GET_VEHICLE_BODY_TYPE"
export const GET_VEHICLE_FUEL_TYPE = "GET_VEHICLE_FUEL_TYPE"
export const GET_VEHICLE_DRIVE_TRAIN = "GET_VEHICLE_DRIVE_TRAIN"
export const GET_VEHICLE_FEATURES = "GET_VEHICLE_FEATURES"
export const REMOVE_ALL = "REMOVE_ALL"
export const TOGGLE_VEHICLE_FEATURES = "TOGGLE_VEHICLE_FEATURES"
export const LOADING_LISTING_VEHICLE_UPDATE_DEALER = "LOADING_LISTING_VEHICLE_UPDATE_DEALER"
export const LOADING_LISTING_VEHICLE_UPDATE_OVERVIEW_DEALER = "LOADING_LISTING_VEHICLE_UPDATE_OVERVIEW_DEALER"
export const LOADING_LISTING_VEHICLE_UPDATE_LOCATION_DEALER = "LOADING_LISTING_VEHICLE_UPDATE_LOCATION_DEALER"
export const LOADING_LISTING_VEHICLE_UPDATE_FEATURES_DEALER = "LOADING_LISTING_VEHICLE_UPDATE_FEATURES_DEALER"
export const LOADING_LISTING_VEHICLE_UPDATE_DESCRIPTION_DEALER = "LOADING_LISTING_VEHICLE_UPDATE_DESCRIPTION_DEALER"
export const LOADING_LISTING_VEHICLE_APPROVED_DEALER = "LOADING_LISTING_VEHICLE_APPROVED_DEALER"
export const LOADING_LISTING_VEHICLE_DECLINE_DEALER = "LOADING_LISTING_VEHICLE_DECLINE_DEALER"
export const LOADING_LISTING_DELETE_IMAGES_DEALER = "LOADING_LISTING_DELETE_IMAGES_DEALER"
export const LOADING_LISTING_UPDATE_IMAGES_DEALER = "LOADING_LISTING_UPDATE_IMAGES_DEALER"
export const LOADING_LISTING_DELETE_LISTING_DEALER = "LOADING_LISTING_DELETE_LISTING_DEALER"
export const LISTING_DELETE_LISTING_SUCCESS_DEALER = "LISTING_DELETE_LISTING_SUCCESS_DEALER"
export const LOADING_LISTING_VEHICLE_UPDATE_MAKE_MODEL_DEALER = "LOADING_LISTING_VEHICLE_UPDATE_MAKE_MODEL_DEALER"
export const UPDATE_VEHICLE_MAKE_MODEL_SUCCESS_DEALER = "UPDATE_VEHICLE_MAKE_MODEL_SUCCESS_DEALER"
export const LISTING_ARCHIVE_SUCCESS_DEALER = "LISTING_ARCHIVE_SUCCESS_DEALER"
export const LISTING_DELETE_LISTING_SUCCESS_SINGLE_DEALER = "LISTING_DELETE_LISTING_SUCCESS_SINGLE_DEALER"
export const LOADING_LISTING_DELETE_LISTING_SINGLE_DEALER = "LOADING_LISTING_DELETE_LISTING_SINGLE_DEALER"
export const LOADING_EXPIRE_UPDATE_LISTING_DEALER = "LOADING_EXPIRE_UPDATE_LISTING_DEALER"
export const REACTIVE_EXPIRE_LISTING_DEALER = "REACTIVE_EXPIRE_LISTING_DEALER"
export const LOADING_DASHBOARD_NEW_APPLICATIONS_DEALER = "LOADING_DASHBOARD_NEW_APPLICATIONS_DEALER"
export const GET_DASHBOARD_NEW_APPLICATIONS_DEALER = "GET_DASHBOARD_NEW_APPLICATIONS_DEALER"
export const LOADING_DASHBOARD_NEW_ACCOUNTS_DEALER = "LOADING_DASHBOARD_NEW_ACCOUNTS_DEALER"
export const GET_DASHBOARD_NEW_ACCOUNTS_DEALER = "GET_DASHBOARD_NEW_ACCOUNTS_DEALER"
export const LOADING_DASHBOARD_NEW_LISTINGS_DEALER = "LOADING_DASHBOARD_NEW_LISTINGS_DEALER"
export const GET_DASHBOARD_NEW_LISTINGS_DEALER = "GET_DASHBOARD_NEW_LISTINGS_DEALER"
/**
 * 
 * Dealer Ship
 * 
 */
export const UPDATE_DEALERSHIP_LOADING = "UPDATE_DEALERSHIP_LOADING"
export const GET_DEALER_SHIP_PROFILE_DATA = "GET_DEALER_SHIP_PROFILE_DATA"
export const REMOVE_ALL_DEALERSHIP_DETAIL = "REMOVE_ALL_DEALERSHIP_DETAIL"
export const DELETE_DEALERSHIP_DOCUMENT_LOADING = "DELETE_DEALERSHIP_DOCUMENT_LOADING"
export const UNSELECT_DEALERSHIP_VEHICLE_LOCATION = "UNSELECT_DEALERSHIP_VEHICLE_LOCATION"

/**
 * 
 * Dealer User
 * 
 */

export const LOADING_USERS = "LOADING_USERS"
export const GET_USERS = "GET_USERS"
export const GET_DEALER_ADMIN_USER_DETAIL = "GET_DEALER_ADMIN_USER_DETAIL"
export const GET_DEALER_ADMIN_USER_ROLES = "GET_DEALER_ADMIN_USER_ROLES"
export const SINGLE_CHECK_DEALER_ADMIN_USER = "SINGLE_CHECK_DEALER_ADMIN_USER"
export const TOGGLE_ALL_CHECK_DEALER_ADMIN_USER = "TOGGLE_ALL_CHECK_DEALER_ADMIN_USER"
export const REMOVE_ALL_STATE_DEALER_ADMIN_USER = "REMOVE_ALL_STATE_DEALER_ADMIN_USER"
export const LOADING_DELETEING_DEALER_ADMIN_USER = "LOADING_DELETEING_DEALER_ADMIN_USER"
export const LOADING_UPDATE_DEALER_ADMIN_PERMISSION = "LOADING_UPDATE_DEALER_ADMIN_PERMISSION"
export const DELETE_SINGLE_AGENT = "DELETE_SINGLE_AGENT"
export const DELETE_MULTIPLE_DEALER_ADMIN_USER = "DELETE_MULTIPLE_DEALER_ADMIN_USER"
export const LOADING_UPDATE_DEALER_ADMIN_USER_DETAIL = "LOADING_UPDATE_DEALER_ADMIN_USER_DETAIL"
export const DEALER_ADMIN_SCREENS = "DEALER_ADMIN_SCREENS"
/**
 * 
 * Settings
 */
export const LOADING_DEALER_ADMIN_UPDATE_PASSWORD = "LOADING_DEALER_ADMIN_UPDATE_PASSWORD"
export const UPDATE_DEALER_ADMIN_PASSWORD = "UPDATE_DEALER_ADMIN_PASSWORD"
export const GET_DEALER_CREDIT_SCORE = "GET_DEALER_CREDIT_SCORE"
export const NEW_DEALER_CREDIT_SCORE = "NEW_DEALER_CREDIT_SCORE"
export const DELETE_DEALER_CREDIT_SCORE = "DELETE_DEALER_CREDIT_SCORE"