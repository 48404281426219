import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
// import { createLogger } from "redux-logger";
import rootReducer from "../_reducers";

// const loggerMiddleware = createLogger();
const composeEnhancers = composeWithDevTools({
  serialize: true,
  devTools:false
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});
const Store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      // loggerMiddleware
    )
  ),
);
export default Store;
