import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: "AIzaSyA1AbBqAgLJL_jZPVs0qmbsC1loO7QkeVs",
  authDomain: "financethatdotca.firebaseapp.com",
  projectId: "financethatdotca",
  storageBucket: "financethatdotca.appspot.com",
  messagingSenderId: "410666681931",
  appId: "1:410666681931:web:6ef0c9a20bcdef4d45afce",
  measurementId: "G-6FB18LW3V8"
};

// Initialize Firebase
const initFirebase = () => {
  console.log('firebase init');
  return initializeApp(firebaseConfig);
};

export const firebaseApp = initFirebase();

// Initialize App Check before any other Firebase services
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LcCqmIqAAAAAEsbOxAj6yrxXlTauczub8synin-'),
  isTokenAutoRefreshEnabled: true, // Automatically refreshes the App Check token
});

// Optionally, retrieve and log the App Check token for debugging
// getToken(appCheck)
//   .then((result) => {
//     console.log('App Check token:', result.token);
//   })
//   .catch((error) => {
//     console.error('Error fetching App Check token:', error);
//   });

// Now initialize Firestore after App Check
export const fireStoreDB = getFirestore(firebaseApp);