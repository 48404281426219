import React, { useState, useEffect } from 'react';
import axios from '../../../_helpers/axiosInterceptorsAdmin';
import { useParams } from 'react-router-dom';
import { API_URL } from "../../../../src/constant";

/** Add Loader */
import Store from "../../../_helpers/store"
import { LOADING_ADMIN_APIS } from "../../../_constants/constants"
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from 'react-router-dom';

const ApplyNowEditApplication = () => {

  const [formData, setFormData] = useState({
    vehicle: '',
    budget: '',
    employment_status: '',
    monthly_salary: '',
    saddress: '',
    city: '',
    province: '',
    postal_code: '',
    dob: '',
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    period_with_employer_id: '',
    residential_status_id: '',
    residential_length_id: '',
  });

  /**
   * set loading and action while loading
   */
  const [isLoading, setIsLoading] = useState(false);
  const [actionWhileLoading, setActionWhileLoading] = useState("loading");
  const openLoader = () => {
    Store.dispatch({
      type: LOADING_ADMIN_APIS,
      status: true
    });
  };

  const closeLoader = () => {
    Store.dispatch({
      type: LOADING_ADMIN_APIS,
      status: false
    });
  };
  const history = useHistory();


  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const { id } = useParams();

  const scrollToBottomIfParameterExists = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const scrollToBottom = urlParams.get('scroll') === 'bottom';
    if (scrollToBottom) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  };
  
  useEffect(() => {
    fetchData();
    scrollToBottomIfParameterExists();
  }, [id]);

  const fetchData = async () => {
    try {
      openLoader()
      const response = await axios.get(`${API_URL}/applynow/applications/${id}/`);
      const { data } = response.data;
      setFormData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      closeLoader();
    }

    try {
      openLoader()
      const frontlineResponse = await axios.get(`${API_URL}/frontline-processed-data-list/`);
      const { data } = frontlineResponse.data;
      setData(data);
    } catch (error) {
      console.error('Error fetching frontline data:', error);
    } finally {
      closeLoader();
    }
};


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    openLoader()
    try {
      await axios.put(`${API_URL}/applynow/applications/${id}/`, formData)
        .then(response => {
          history.push('/admin/applynow/applications');
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            setErrors(error.response.data.errors);
          }
          closeLoader()
        });
      closeLoader()
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      }
      closeLoader()
    }
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text={actionWhileLoading}
    >
      <div>
        <div className="app-form-main clearfix applyNowMain" style={{ padding: '30px 0px 0px 0px' }} >
          <a className="back" href="/admin/applynow/applications" style={{ top: "-15px", left: "0px" }}>
            Back to Apply Now </a>

          <div className="Pending-ListHead">
            <h1>Update "applynow.financethat.ca" submitted application.</h1>
          </div>
          <div className="clearfix"></div>
          <form onSubmit={handleSubmit} className='DealerID-Container applyNowEditForm'>

            {/* Left side form */}
            <div className="Admin-DealerLeft" style={{ maxWidth: '800px', width: 'auto', float: 'none' }}>
              <div className="InnerDealer-Container">
                <div className="DealerID-Container">
                  <div className="CustomMake-Form pt-0">
                    <div className="MakeLeft-Form">
                      <label>What would you like to finance?</label>
                      <select name="vehicle" value={formData.vehicle} onChange={handleChange}>
                        <option value="">Select Vehicle Type</option>
                        {Object.entries(data.vehicle || {}).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                      {errors.vehicle && <p style={{ color: 'red' }}>{errors.vehicle}</p>}
                    </div>

                    <div className="MakeLeft-Form">
                      <label>What is your budget?</label>
                      <select name="budget" value={formData.budget} onChange={handleChange}>
                        <option value="">Select Budget</option>
                        {Object.entries(data.monthly_budget || {}).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                      {errors.budget && <p style={{ color: 'red' }}>{errors.budget}</p>}
                    </div>
                  </div>

                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form">
                      <label>What is your employment status?</label>
                      <select name="employment_status" value={formData.employment_status} onChange={handleChange}>
                        <option value="">Select Employment Status</option>
                        {Object.entries(data['employment-status'] || {}).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value.replace(/_/g, ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </option>
                        ))}
                      </select>
                      {errors.employment_status && <p style={{ color: 'red' }}>{errors.employment_status}</p>}
                    </div>

                    <div className="MakeLeft-Form">
                      <label>What is your Net monthly Income?</label>
                      <input type="text" name="monthly_salary" value={formData.monthly_salary} onChange={handleChange} />
                      {errors.monthly_salary && <p style={{ color: 'red' }}>{errors.monthly_salary}</p>}
                    </div>
                  </div>

                  <h1 className="mt-5 pt-2" style={{ fontSize: '18px' }}>What is your address?</h1>
                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form" style={{ width: '100%' }}>
                      <label>Address</label>
                      <input type="text" name="saddress" value={formData.saddress} onChange={handleChange} />
                      {errors.saddress && <p style={{ color: 'red' }}>{errors.saddress}</p>}
                    </div>

                  </div>

                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form">
                      <label>City</label>
                      <input type="text" name="city" value={formData.city} onChange={handleChange} />
                      {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                    </div>
                    <div className="MakeLeft-Form">
                      <label>Province</label>
                      <select name="province" value={formData.province} onChange={handleChange}>
                        <option value="">Select Province</option>
                        {Object.entries(data.provinces || {}).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                      {errors.province && <p style={{ color: 'red' }}>{errors.province}</p>}
                    </div>

                  </div>
                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form">
                      <label>Postal Code</label>
                      <input type="text" name="postal_code" value={formData.postal_code} onChange={handleChange} />
                      {errors.postal_code && <p style={{ color: 'red' }}>{errors.postal_code}</p>}
                    </div>
                  </div>
                  <h1 className="mt-5 pt-2" style={{ fontSize: '18px' }}>What is your Name and Contact information?</h1>
                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form">
                      <label>First Name</label>
                      <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} />
                      {errors.firstname && <p style={{ color: 'red' }}>{errors.firstname}</p>}
                    </div>
                    <div className="MakeLeft-Form">
                      <label>Last Name</label>
                      <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} />
                      {errors.lastname && <p style={{ color: 'red' }}>{errors.lastname}</p>}
                    </div>
                  </div>

                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form">
                      <label>What is your Date of Birth?</label>
                      <input type="date" name="dob" value={formData.dob} onChange={handleChange} />
                      {errors.dob && <p style={{ color: 'red' }}>{errors.dob}</p>}
                    </div>
                    <div className="MakeLeft-Form">
                      <label>Phone</label>
                      <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                      {errors.phone && <p style={{ color: 'red' }}>{errors.phone}</p>}
                    </div>
                  </div>

                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form">
                      <label>Email</label>
                      <input type="email" name="email" value={formData.email} onChange={handleChange} />
                      {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                    </div>
                  </div>
                  <h1 className="mt-5 pt-2" style={{ fontSize: '18px' }}>Frontline Fields</h1>
                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form">
                      <label>Employer Province<span style={{ color: '#FE5600', fontSize: '10px' }}>* (Required for frontline)</span></label>
                      <select name="employer_province_name" value={formData.employer_province_name} onChange={handleChange}>
                        <option value="">Select Employer Province</option>
                        {Object.entries(data.provinces || {}).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                      {errors.employer_province_name && <p style={{ color: 'red' }}>{errors.employer_province_name}</p>}
                    </div>
                    <div className="MakeLeft-Form">
                      <label>Employer Phone Number<span style={{ color: '#FE5600', fontSize: '10px' }}>* (Required for frontline)</span></label>
                      <input type="text" name="employer_phone_number" value={formData.employer_phone_number} onChange={handleChange} />
                      {errors.employer_phone_number && <p style={{ color: 'red' }}>{errors.employer_phone_number}</p>}
                    </div>
                  </div>

                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form">
                      <label>Residential Status<span style={{ color: '#FE5600', fontSize: '10px' }}>* (Required for frontline)</span></label>
                      <select name="residential_status_id" value={formData.residential_status_id} onChange={handleChange}>
                        <option value="">Select Residential Status</option>
                        {Object.entries(data.residential_status || {}).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                      {errors.residential_status_id && <p style={{ color: 'red' }}>{errors.residential_status_id}</p>}
                    </div>
                    <div className="MakeLeft-Form">
                      <label>Residential Length<span style={{ color: '#FE5600', fontSize: '10px' }}>* (Required for frontline)</span></label>
                      <select name="residential_length_id" value={formData.residential_length_id} onChange={handleChange}>
                        <option value="">Select Residential Length</option>
                        {Object.entries(data.residential_length || {}).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                      {errors.residential_length_id && <p style={{ color: 'red' }}>{errors.residential_length_id}</p>}
                    </div>
                  </div>

                  <div className="CustomMake-Form pt-3">
                    <div className="MakeLeft-Form">
                      <label>Period With Employer<span style={{ color: '#FE5600', fontSize: '10px' }}>* (Required for frontline)</span></label>
                      <select name="period_with_employer_id" value={formData.period_with_employer_id} onChange={handleChange}>
                        <option value="">Select Period</option>
                        {Object.entries(data.period_with_employer || {}).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                      {errors.period_with_employer_id && <p style={{ color: 'red' }}>{errors.period_with_employer_id}</p>}
                    </div>
                  </div>
                </div>
                <div className="footer-btns-holder clearfix" style={{ marginTop: '50px' }}>
                  <button type="submit" className="btn btn-primary active" style={{ maxWidth: '200px' }}> Update </button>
                </div>
              </div>

            </div>

          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default ApplyNowEditApplication;
