import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import RetailProgramLinkComponent from '../../component/retailProgramLinkComponent/RetailProgramLinkComponent';

class LeadGenerator extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Settings</title>
                    <meta name="description" content="" />
                </Helmet>
                <RetailProgramLinkComponent />
            </React.Fragment>
        )
    }
}

export default LeadGenerator