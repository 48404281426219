import axios from "../../../../_helpers/axiosInterceptors";
import { API_URL } from "../../../../constant";

export const fetchManualApplication = (id) => {
  const url = `${API_URL}/manual-application/${id}/`;
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json; charset=utf8" },
    url,
  };
  return axios(options);
  // .then(response => {
  //     if (response.data.success == true) {
  //         let dt = response.data.data;

  //     }
  // })
  // .catch(err => {
  //     const message = err.response !== undefined && err.response !== null ? err.response.data.message !== undefined ? err.response.data.message : err.message : err.message
  //     toastr.error('Error', message.toString())
  // })
};