import React, { Component } from "react";
import axios from "../../../../_helpers/axiosInterceptorsAdmin";
import FileUploader from "./FileUploader";
import ApplicationHeaderAdmin from "../ApplicationHeaderAdmin";
import PurchaseReceiptTable from "./PurchaseReceiptTable";
import LoadingOverlay from 'react-loading-overlay';
import { API_URL } from "../../../../constant";
import { toastr } from 'react-redux-toastr';

class DeliveryVerification extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      actionWhileLoading: 'Analyzing your request. Please wait.',
      purchaseReceiptTableKey: 0,
      deleteReceiptTableKey: 0,
      isCustomerReceiveProduct: false,
      isFileUploaded: false
    };
  }

  handleFileUploadSuccess = () => {
    this.setState(prevState => ({
      purchaseReceiptTableKey: prevState.purchaseReceiptTableKey + 1,
      isFileUploaded: true
    }));
  };

  handleFileDeleteSuccess = () => {
    this.setState(prevState => ({
      deleteReceiptTableKey: prevState.deleteReceiptTableKey + 1,
      isFileUploaded: false
    }));
  };

  handleRadioChange = (e) => {
    this.setState({ isCustomerReceiveProduct: e.target.value === "true" });
  };

  handleSaveButtonClick = () => {
    const { isCustomerReceiveProduct } = this.state;
    axios.put(`${API_URL}/retail-applications/update-customer-receive-product-status/${this.props.applicationId}/`, {
      is_customer_receive_product: isCustomerReceiveProduct
    })
      .then(response => {
        toastr.success('Customer receive product status updated successfully.');

        // Trigger rerender by updating a key in the state
        this.setState(prevState => ({
          purchaseReceiptTableKey: prevState.purchaseReceiptTableKey + 1,
        }));
        this.props.updateApplicationStatus({ value: "12", label: "Funded" });
      })
      .catch(error => {
        toastr.error('Failed to update customer receive product status.');
      });
  };

  render() {
    const { isLoading, actionWhileLoading, purchaseReceiptTableKey, deleteReceiptTableKey } = this.state;
    // Destructure applicationId from props inside render method
    const { applicationId } = this.props;
    // Render PurchaseReceiptTable only when applicationId is available
    const renderPurchaseReceiptTable = applicationId ? (
      <PurchaseReceiptTable
        key={purchaseReceiptTableKey} // Change key to force re-render
        applicationId={applicationId}
        setIsLoading={(value) => this.setState({ isLoading: value })}
        setActionWhileLoading={(value) => this.setState({ actionWhileLoading: value })}
        onFileDeleteSuccess={this.handleFileDeleteSuccess}
        updateCustomerPurchaseReceipt={(value) => this.setState({ isCustomerReceiveProduct: value })}
        updateIsFileUploaded={(value) => this.setState({ isFileUploaded: value })}
        updateApplicationStatus={this.props.updateApplicationStatus}
      />
    ) : null;

    return (
      <LoadingOverlay
        active={isLoading}
        spinner
        text={actionWhileLoading}
      >
        <React.Fragment>
          <div className="app-form-content">
            <div className="app-form-content-inner">
              <ApplicationHeaderAdmin {...this.props} />
              <ul
                className="nav nav-tabs-2 admin-tabs three-items text-left"
                id="formAppTabs"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active pl-5"
                    id="delivery-verification-tab"
                    data-toggle="tab"
                    href="#personal-detail"
                    role="tab"
                    aria-controls="personal-detail"
                  >
                    <span className="tabs-text delivery-verification">
                      Delivery Verification
                    </span>
                  </a>
                </li>
              </ul>

              <div className="tab-content" id="formAppTabsContent">
                <div
                  className="tab-pane fade active show clearfix salman3"
                  id="deliveryVerification"
                  role="tabpanel"
                  aria-labelledby="deliveryVerification-tab"
                >
                  <div className="forms-head clearfix">
                    <h1 className="float-left lineNone">Upload Purchase Receipt</h1>
                  </div>
                </div>

                <div className="upload-doc-content deliveryVerificationUpload">

                  <FileUploader
                    key={deleteReceiptTableKey}
                    applicationId={this.props.applicationId}
                    setIsLoading={(value) => this.setState({ isLoading: value })}
                    setActionWhileLoading={(value) => this.setState({ actionWhileLoading: value })}
                    onFileUploadSuccess={this.handleFileUploadSuccess}
                  />

                  {this.state.isFileUploaded && (
                    <div className="hasCustomerReceive">
                      <div className="titleCRP">Has the customer received the product(s)?<em>*</em></div>
                      <div className="">
                        <label className="customRadioButton">Yes
                          <input
                            type="radio"
                            name="customer_purchase_receipt"
                            value="true"
                            onChange={this.handleRadioChange}
                            checked={this.state.isCustomerReceiveProduct === true}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="customRadioButton">No
                          <input
                            type="radio"
                            name="customer_purchase_receipt"
                            value="false"
                            onChange={this.handleRadioChange}
                            checked={this.state.isCustomerReceiveProduct === false}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                {renderPurchaseReceiptTable}

                {this.state.isFileUploaded && this.state.isCustomerReceiveProduct && (
                  <div className="footer-btns-holder clearfix">
                    <button className="btn btn-primary float-left">Back</button>
                    <button onClick={this.handleSaveButtonClick} className="btn btn-primary float-right active">Save</button>
                  </div>
                )}

              </div>

            </div>
          </div>
        </React.Fragment>
      </LoadingOverlay>
    );
  }
}

export default DeliveryVerification;
