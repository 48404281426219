/**
 *
 *  Auth Actions
 *
 */
export const USERS_LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOADING = "LOADING";
export const USERS_LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const USERS_LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const VERIFY_LOGIN_USER = "VERIFY_LOGIN_USER";
export const USERS_LOGOUT = "USERS_LOGOUT";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const ADMIN_SCREENS = "ADMIN_SCREENS";
export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";
export const USER_FORGOT_PASSWORD_LOADING = "USER_FORGOT_PASSWORD_LOADING";
export const RESET_EMAIL_SENT = "RESET_EMAIL_SENT";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const IS_LOADING_TRUE = "IS_LOADING_TRUE";
export const IS_LOADING_FALSE = "IS_LOADING_FALSE";
export const USERS_REGISTER_SUCCESS = "USERS_REGISTER_SUCCESS";
export const USERS_REGISTER_SUCCESS_POST_APP =
  "USERS_REGISTER_SUCCESS_POST_APP";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";
export const OTP_SEND_START = "OTP_SEND_START";
export const OTP_SEND_SUCCESS = "OTP_SEND_SUCCESS";
export const OTP_SEND_FAILURE = "OTP_SEND_FAILURE";
export const CHANGE_OTP_PHONE_NO = "CHANGE_OTP_PHONE_NO";
export const PERSONAL_LOAN_ZAP_LOADING = "PERSONAL_LOAN_ZAP_LOADING"
export const PERSONAL_LOAN_ZAP_SUCCESS = "PERSONAL_LOAN_ZAP_SUCCESS"
/**
 *  Dealer Auth Actions
 *
 */
export const DEALER_REGISTER = "DEALER_REGISTER";
/**
 *  Component Actions
 *
 */
export const BUYER_BANNER_TOGGLE_FILTER = "BUYER_BANNER_TOGGLE_FILTER";
export const TOGGLE_THEME_NAVBAR = "TOGGLE_THEME_NAVBAR";
export const BUYER_BANNER_TOGGLE_FILTER_MOBILE =
  "BUYER_BANNER_TOGGLE_FILTER_MOBILE";
/**
 *
 * Chat Actions
 *
 */
export const CHAT_LIST = "CHAT_LIST";
export const CHAT = "CHAT";
export const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE";
export const RESET_CHAT = "RESET_CHAT";
export const BLOCK_TOGGLE = "BLOCK_TOGGLE";
export const BLOCK_LOADING = "BLOCK_LOADING";
export const CHAT_UNREAD_UPDATE = "CHAT_UNREAD_UPDATE";
export const MESSAGE_SEND = "MESSAGE_SEND";
export const TOGGLE_CHAT_MODULE = "TOGGLE_CHAT_MODULE";
export const CHAT_IMAGE_UPLOADING = "CHAT_IMAGE_UPLOADING";
export const DELETE_CHAT = "DELETE_CHAT";
export const DELETE_CHAT_LOADING = "DELETE_CHAT_LOADING";
export const SEARCH_CHAT_FRIENDS = "SEARCH_CHAT_FRIENDS";
/**
 *
 *  Add Post
 *
 */
export const GET_TYPE_VEHICLES = "GET_TYPE_VEHICLES";
export const GET_VEHICLE_MAKES = "GET_VEHICLE_MAKES";
export const GET_VEHICLE_MODEL = "GET_VEHICLE_MODEL";
export const GET_VEHICLE_BODY_TYPE = "GET_VEHICLE_BODY_TYPE";
export const GET_VEHICLE_FUEL_TYPE = "GET_VEHICLE_FUEL_TYPE";
export const GET_VEHICLE_DRIVE_TRAIN = "GET_VEHICLE_DRIVE_TRAIN";
export const GET_VEHICLE_FEATURES = "GET_VEHICLE_FEATURES";
export const TOGGLE_VEHICLE_FEATURES = "TOGGLE_VEHICLE_FEATURES";
export const SEND_SUCCESS_MESSAGE = "SEND_SUCCESS_MESSAGE";
export const REMOVE_ALL_FEATURES = "REMOVE_ALL_FEATURES";
export const SAVED_POST_START = "SAVED_POST_START";
export const SAVED_POST_END = "SAVED_POST_END";
export const TOGGLE_VEHICLE_MAKE = "TOGGLE_VEHICLE_MAKE";
export const TOGGLE_VEHICLE_MODEL = "TOGGLE_VEHICLE_MODEL";
export const TOGGLE_VEHICLE_TYPE = "TOGGLE_VEHICLE_TYPE";
export const CREATE_PAYMENT_INTENT = "CREATE_PAYMENT_INTENT";
export const REMOVE_CLIENT_SECRET = "REMOVE_CLIENT_SECRET";
export const VEHICLE_FEATURES_LOADER = "VEHICLE_FEATURES_LOADER";
export const VEHICLE_DRIVE_TRAIN_LOADER = "VEHICLE_DRIVE_TRAIN_LOADER";
export const VEHICLE_FUEL_TYPE_LOADER = "VEHICLE_FUEL_TYPE_LOADER";
export const VEHICLE_BODY_TYPE_LOADER = "VEHICLE_BODY_TYPE_LOADER";
export const REMOVE_SPECIFIC_STATE = "REMOVE_SPECIFIC_STATE";
export const PAYPAL_PAYMENT_DONE = "PAYPAL_PAYMENT_DONE";
export const PAYPAL_PAYMENT_INTENT = "PAYPAL_PAYMENT_INTENT";
export const REMOVE_CARD_INTENTS = "REMOVE_CARD_INTENTS";
export const REMOVE_ALL_SELECTED_FEATURES = "REMOVE_ALL_SELECTED_FEATURES";
/**
 *
 *  List Post
 *
 *
 */
export const GET_POST_LIST_DETAIL = "GET_POST_LIST_DETAIL";
export const GET_POST_LIST_FILTERS = "GET_POST_LIST_FILTERS";
export const GET_POST_LIST_DETAIL_NEXT = "GET_POST_LIST_DETAIL_NEXT";
export const POST_LIST_NEXT_DETAIL_LOADING = "POST_LIST_NEXT_DETAIL_LOADING";

/**
 *  List Post Filter
 *
 */
export const FILTER_ON_PRICE_RANGE = "FILTER_ON_PRICE_RANGE";
export const FILTER_ON_TRIM = "FILTER_ON_TRIM";
export const FILTER_ON_YEAR_RANGE = "FILTER_ON_YEAR_RANGE";
export const FILTER_ON_TRANSMISSION = "FILTER_ON_TRANSMISSION";
export const FILTER_ON_KILOMETER_RANGE = "FILTER_ON_KILOMETER_RANGE";
export const FILTER_ON_CONDITION = "FILTER_ON_CONDITION";
export const FILTER_ON_SELLER_TYPE = "FILTER_ON_SELLER_TYPE";
export const FILTER_ON_COLOR = "FILTER_ON_COLOR";
export const FILTER_ON_FEATURES = "FILTER_ON_FEATURES";
export const CHANGE_TYPE_OF_VEHICLE = "CHANGE_TYPE_OF_VEHICLE";
export const FILTER_ON_MAKE = "FILTER_ON_MAKE";
export const FILTER_ON_MODEL = "FILTER_ON_MODEL";
export const SAVED_AD_POST = "SAVED_AD_POST";
export const ADD_FILTERS = "ADD_FILTERS";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const REMOVE_FEATURE_NAME = "REMOVE_FEATURE_NAME";
export const REMOVE_ALL_FILTER = "REMOVE_ALL_FILTER";
export const REMOVE_ALL_POST_STATE = "REMOVE_ALL_POST_STATE";
export const REMOVE_ALL_POST = "REMOVE_ALL_POST";
export const REMOVE_ALL = "REMOVE_ALL";
export const GET_VEHICLE_TRIMS = "GET_VEHICLE_TRIMS";
export const TOGGLE_VEHICLE_TRIMS = "TOGGLE_VEHICLE_TRIMS";
export const POST_LIST_DETAIL_LOADING = "POST_LIST_DETAIL_LOADING";
export const GET_POST_LIST_FILTERS_VALUES = "GET_POST_LIST_FILTERS_VALUES";
export const GET_POST_LIST_FILTERS_LOADING = "GET_POST_LIST_FILTERS_LOADING";
export const GET_AD_POST_DETAIL_EDIT = "GET_AD_POST_DETAIL_EDIT";
export const GET_POST_AD_EDIT_LOADING = "GET_POST_AD_EDIT_LOADING";
export const DEFAULT_SELECT_FEATURES = "DEFAULT_SELECT_FEATURES";
export const GET_TOP_ADS = "GET_TOP_ADS";
export const TOP_AD_LOADING = "TOP_AD_LOADING";
export const TOGGLE_LISTING_MAKE = "TOGGLE_LISTING_MAKE";
export const GET_MAKE_LISTING = "GET_MAKE_LISTING";
export const TOGGLE_LISTING_MODEL = "TOGGLE_LISTING_MODEL";
export const GET_MODEL_LISTING = "GET_MODEL_LISTING";
export const GET_MULTI_MODEL_LISTING = "GET_MULTI_MODEL_LISTING";
export const TOGGLE_LISTING_VEHICLE_MULTI_MODEL =
  "TOGGLE_LISTING_VEHICLE_MULTI_MODEL";
export const LOADING_SAVED_AD = "LOADING_SAVED_AD";
export const SAVED_AD_POST_TOP_ADD = "SAVED_AD_POST_TOP_ADD";
export const GET_USER_PROFILE_DATA_FOR_LISTING =
  "GET_USER_PROFILE_DATA_FOR_LISTING";
export const GET_DEALER_PROFILE_DATA_FOR_LISTING =
  "GET_DEALER_PROFILE_DATA_FOR_LISTING";
/**
 *
 * Add Post Detail Actions
 */
export const GET_AD_POST_DETAIL = "GET_AD_POST_DETAIL";
export const CHANGE_DOWN_PAYMENT = "CHANGE_DOWN_PAYMENT";
export const CHANGE_CREDIT_SCORE = "CHANGE_CREDIT_SCORE";
export const CHANGE_IN_TAX = "CHANGE_IN_TAX";
export const REMOVE_ALL_RECORD_POST_DETAIL = "REMOVE_ALL_RECORD_POST_DETAIL";
export const SAVED_AD_POST_DETAIL = "SAVED_AD_POST_DETAIL";
export const LOADING_SAVED_AD_DETAIL = "LOADING_SAVED_AD_DETAIL";

/**
 * Home Actions
 *
 */
export const GET_UNDER_LISTING = "GET_UNDER_LISTING";
export const GET_FEATURED_LISTING = "GET_FEATURED_LISTING";
export const GET_NEW_LISTING = "GET_NEW_LISTING";
export const GET_REDUCE_PRICE_LISTING = " GET_REDUCE_PRICE_LISTING";
export const UNDER_LISTING_LOADING = "UNDER_LISTING_LOADING";
export const FEATURE_LISTING_LOADING = "FEATURE_LISTING_LOADING";
export const NEW_LISTING_LOADING = "NEW_LISTING_LOADING";
export const REDUCE_PRICE_LOADING = "REDUCE_PRICE_LOADING";
export const GET_HOME_VEHICLE_DETAIL = "GET_HOME_VEHICLE_DETAIL";
export const HOME_VEHICLE_DETAIL_LOADING = "HOME_VEHICLE_DETAIL_LOADING";
export const CHANGE_MONTHLY_PAYMENT_HOME = "CHANGE_MONTHLY_PAYMENT_HOME";
export const GET_DOWN_PAYMENT = "GET_DOWN_PAYMENT";
export const CHANGE_CREDIT_SCORE_HOME = "CHANGE_CREDIT_SCORE_HOME";
export const SAVED_AD_POST_HOME = "SAVED_AD_POST_HOME";
export const LOADING_SAVED_AD_POST_HOME = "LOADING_SAVED_AD_POST_HOME";
export const CHNAGE_MAP_LNG_LAT = "CHNAGE_MAP_LNG_LAT";
export const CHNAGE_AUTO_COMPLETE_LNG_LAT = "CHNAGE_AUTO_COMPLETE_LNG_LAT";
export const TOGGLE_HOME_VEHICLE_TYPE = "TOGGLE_HOME_VEHICLE_TYPE";
export const GET_TYPE_VEHICLES_HOME = "GET_TYPE_VEHICLES_HOME";
export const GET_MULTI_MODEL_HOME = "GET_MULTI_MODEL_HOME";
export const TOGGLE_HOME_VEHICLE_MULTI_MODEL =
  "TOGGLE_HOME_VEHICLE_MULTI_MODEL";
export const GET_HOME_MOTORCYCLE_DETAIL = "GET_HOME_MOTORCYCLE_DETAIL";
export const HOME_MOTORCYCLE_DETAIL_LOADING = "HOME_MOTORCYCLE_DETAIL_LOADING";
export const HOME_SMALL_EQUIPMENT_DETAIL_LOADING =
  "HOME_SMALL_EQUIPMENT_DETAIL_LOADING";
export const GET_HOME_SMALL_EQUIPMENT_DETAIL =
  "GET_HOME_SMALL_EQUIPMENT_DETAIL";
export const HOME_POWERSPORT_DETAIL_LOADING = "HOME_POWERSPORT_DETAIL_LOADING";
export const GET_HOME_POWERSPORT_DETAIL = "GET_HOME_POWERSPORT_DETAIL";
export const HOME_RV_DETAIL_LOADING = "HOME_RV_DETAIL_LOADING";
export const GET_HOME_RV_DETAIL = "GET_HOME_RV_DETAIL";
export const HOME_BOAT_DETAIL_LOADING = "HOME_BOAT_DETAIL_LOADING";
export const GET_HOME_BOAT_DETAIL = "GET_HOME_BOAT_DETAIL";
export const HOME_TRAILER_DETAIL_LOADING = "HOME_TRAILER_DETAIL_LOADING";
export const GET_HOME_TRAILER_DETAIL = "GET_HOME_TRAILER_DETAIL";
/**
 * User Profile Actions
 *
 */
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const CHANGE_PROFILE_INPUT = "CHANGE_PROFILE_INPUT";
export const GET_USER_PROFILE_DATA = "GET_USER_PROFILE_DATA";
export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const PASSWORD_PROFILE_UPDATE_LOADING =
  "PASSWORD_PROFILE_UPDATE_LOADING";
export const REMOVE_ALL_PROFILE_DETAIL = "REMOVE_ALL_PROFILE_DETAIL";
/**
 * Dealer Profile Actions
 *
 */
export const GET_DEALER_PROFILE_DATA = "GET_DEALER_PROFILE_DATA";
/**  My Ads */
export const GET_USER_MY_ADS = "GET_USER_MY_ADS";
export const DELETE_USER_ADD = "DELETE_USER_ADD";
export const LOADING_USER_ADD = "LOADING_USER_ADD";
/**  Saved Ads */
export const GET_USER_SAVED_ADS = "GET_USER_SAVED_ADS";
export const DELETE_SAVED_ADD = "DELETE_SAVED_ADD";
export const DELETE_POST_ADD = "DELETE_POST_ADD";
export const LOADING_SAVED_ADD = "LOADING_SAVED_ADD";

/**
 * Post Application
 */
export const START_POST_APPLICATION = "START_POST_APPLICATION";
export const POST_APPLICATION_SUCCESS = "POST_APPLICATION_SUCCESS";
export const END_POST_APPLICATION = "END_POST_APPLICATION";
export const GET_POST_APPLICATIONS = "GET_POST_APPLICATIONS";
export const GET_DMS_POST_APPLICATIONS = "GET_DMS_POST_APPLICATIONS";
export const GET_STOCK_RECORD_BY_ID = "GET_STOCK_RECORD_BY_ID";
export const RESUBMIT_POST_APP = "RESUBMIT_POST_APP";
export const SET_RESUBMIT_TRUE = "SET_RESUBMIT_TRUE";
export const CONFIRM_POST_APPLICATION = "CONFIRM_POST_APPLICATION";
export const REMOVE_ALL_POST_APP_STATE = "REMOVE_ALL_POST_APP_STATE";
export const LOADING_STOCK_BY_ID = "LOADING_STOCK_BY_ID";
export const DISCARD_POST_STATE = "DISCARD_POST_STATE";
export const GET_CREDIT_REPORT_DATA = "GET_CREDIT_REPORT_DATA";
export const GET_EQUIFAX_CREDIT_REPORT_DATA = "GET_EQUIFAX_CREDIT_REPORT_DATA";
export const GET_TRANSUNION_HARD_HIT_CREDIT_REPORT_DATA = "GET_TRANSUNION_HARD_HIT_CREDIT_REPORT_DATA";
export const PUT_UPDATE_SIN_REQUEST = "PUT_UPDATE_SIN_REQUEST";

/**
 *
 * Edit Post App
 */
export const GET_ADDTIONAL_TYPE = "GET_ADDTIONAL_TYPE";
export const GET_VEHICLE_MAKES_EDIT_POST_APP =
  "GET_VEHICLE_MAKES_EDIT_POST_APP";
export const TOGGLE_VEHICLE_MAKE_EDIT_POST_APP =
  "TOGGLE_VEHICLE_MAKE_EDIT_POST_APP";
export const GET_VEHICLE_MODEL_EDIT_POST_APP =
  "GET_VEHICLE_MODEL_EDIT_POST_APP";
export const TOGGLE_VEHICLE_MODEL_EDIT_POST_APP =
  "TOGGLE_VEHICLE_MODEL_EDIT_POST_APP";
export const TOGGLE_VEHICLE_TRIMS_EDIT_POST_APP =
  "TOGGLE_VEHICLE_TRIMS_EDIT_POST_APP";
export const GET_VEHICLE_TRIMS_EDIT_POST_APP =
  "GET_VEHICLE_TRIMS_EDIT_POST_APP";
export const GET_EDIT_APPLICAITION_DETAIL = "GET_EDIT_APPLICAITION_DETAIL";
export const EDIT_APPLICATION_NOT_FOUND = "EDIT_APPLICATION_NOT_FOUND"
export const EDIT_POST_APP_LOADING = "EDIT_POST_APP_LOADING";
export const EDIT_POST_APP_UPDATE = "EDIT_POST_APP_UPDATE";
export const DELETE_ADDTIONAL_INFO_LOADING = "DELETE_ADDTIONAL_INFO_LOADING";
export const DELETE_ADDTIONAL_INFO = "DELETE_ADDTIONAL_INFO";
export const DELETE_VEHICLE_DETAIL = "DELETE_VEHICLE_DETAIL";
export const DELETE_VEHICLE_DETAIL_LOADING = "DELETE_VEHICLE_DETAIL_LOADING";
export const DELETE_UPLOADED_DOC = "DELETE_UPLOADED_DOC";
export const DELETE_UPLOAD_DOC_LOADING = "DELETE_UPLOAD_DOC_LOADING";
export const LOADING_FLINKS_REQUEST_ID = "LOADING_FLINKS_REQUEST_ID";
export const FLINKS_REQUEST_ID_RESPONSE = "FLINKS_REQUEST_ID_RESPONSE";
export const LOADING_FLINKS_LOGIN_ID = "LOADING_FLINKS_LOGIN_ID";
export const FLINKS_LOGIN_ID_RESPONSE = "FLINKS_LOGIN_ID_RESPONSE";
export const EDIT_POST_STOCK_DETAIL = "EDIT_POST_STOCK_DETAIL";
export const LOADING_EDIT_POST_STOCK_DETAIL = "LOADING_EDIT_POST_STOCK_DETAIL";
export const DELETE_COAPPLICANT_LOADING = "DELETE_COAPPLICANT_LOADING";
export const DELETE_COAPPLICANT_INFO = "DELETE_COAPPLICANT_INFO";
export const LOADING_EDIT_APPLICATION = "LOADING_EDIT_APPLICATION";
export const UPDATE_APPLICATION_STATUSES = "UPDATE_APPLICATION_STATUSES";
export const EDIT_POST_APP_UPDATE_UPLOAD_DOC =
  "EDIT_POST_APP_UPDATE_UPLOAD_DOC";
export const GET_ASSIGNED_DEALER = "GET_ASSIGNED_DEALER"
export const GET_UNIT_FROM = "GET_UNIT_FROM"

/**
 *
 * Admin Constants
 *
 */
export const LOADING_ADMIN_APIS = "LOADING_ADMIN_APIS";
/**
 *  Admin DashBoard
 *  */
export const LOADING_DASHBOARD_NEW_APPLICATIONS =
  "LOADING_DASHBOARD_NEW_APPLICATIONS";
export const GET_DASHBOARD_NEW_APPLICATIONS = "GET_DASHBOARD_NEW_APPLICATIONS";
export const LOADING_DASHBOARD_NEW_ACCOUNTS = "LOADING_DASHBOARD_NEW_ACCOUNTS";
export const GET_DASHBOARD_NEW_ACCOUNTS = "GET_DASHBOARD_NEW_ACCOUNTS";
export const LOADING_DASHBOARD_NEW_LISTINGS = "LOADING_DASHBOARD_NEW_LISTINGS";
export const GET_DASHBOARD_NEW_LISTINGS = "GET_DASHBOARD_NEW_LISTINGS";
/**
 *  Accounts
 */
export const LOADING_PENDING_ACCOUNT = "LOADING_PENDING_ACCOUNT";
export const GET_PENDING_ACTIVE_ACCOUNTS = "GET_PENDING_ACTIVE_ACCOUNTS";
export const SINGLE_CHECK_PENDING = "SINGLE_CHECK_PENDING";
export const TOGGLE_ALL_CHECK = "TOGGLE_ALL_CHECK";
export const UPDATE_ACCOUNT_ROW_DATA = "UPDATE_ACCOUNT_ROW_DATA";
export const UPDATE_ACCOUNT_DEALER_DATA = "UPDATE_ACCOUNT_DEALER_DATA"
export const REMOVE_UPDATE_ROW = "REMOVE_UPDATE_ROW";
export const LOADING_PENDING_ACCOUNT_DECLINE =
  "LOADING_PENDING_ACCOUNT_DECLINE";
export const LOADING_PENDING_ACCOUNT_APPROVED =
  "LOADING_PENDING_ACCOUNT_APPROVED";
export const LOADING_PRIVATE_ACCOUNT_UPDATE = "LOADING_PRIVATE_ACCOUNT_UPDATE";
export const LOADING_DEALER_ACCOUNT_UPDATE = "LOADING_DEALER_ACCOUNT_UPDATE";
export const LOADING_DEALER_DETAIL_UPDATE = "LOADING_DEALER_DETAIL_UPDATE";
export const REMOVE_ALL_STATE_ACCOUNTS = "REMOVE_ALL_STATE_ACCOUNTS";

export const DELETE_SINGLE_ACCOUNT = "DELETE_SINGLE_ACCOUNT";
export const LOADING_DELETE_SINGLE_ACCOUNT = "LOADING_DELETE_SINGLE_ACCOUNT";
export const DELETE_MULTIPLE_ACCOUNT = "DELETE_MULTIPLE_ACCOUNT";
export const ADD_CARD_BILLING = "ADD_CARD_BILLING"
export const BILLING_LOADING = "BILLING_LOADING"
export const BILLING_INVOICES = "BILLING_INVOICES"
export const NEW_DEALER_CREDIT_SCORE = "NEW_DEALER_CREDIT_SCORE"
export const DELETE_DEALER_CREDIT_SCORE = "DELETE_DEALER_CREDIT_SCORE"
export const GET_DEALER_CREDIT_SCORE = "GET_DEALER_CREDIT_SCORE"
/**
 * End Account
 *
 *  Accounts
 */
export const LOADING_LISTING = "LOADING_LISTING";
export const GET_LISTING = "GET_LISTING";
export const SINGLE_CHECK_LISTING = "SINGLE_CHECK_LISTING";
export const TOGGLE_ALL_CHECK_LISTING = "TOGGLE_ALL_CHECK_LISTING";
export const REMOVE_ALL_STATE_LISTING = "REMOVE_ALL_STATE_LISTING";
export const GET_LISTING_DETAIL = "GET_LISTING_DETAIL";
export const LOADING_LISTING_DETAIL = "LOADING_LISTING_DETAIL";
export const REMOVE_DETAIL_EDIT = "REMOVE_DETAIL_EDIT";
export const LOADING_LISTING_VEHICLE_UPDATE = "LOADING_LISTING_VEHICLE_UPDATE";
export const LOADING_LISTING_VEHICLE_UPDATE_OVERVIEW =
  "LOADING_LISTING_VEHICLE_UPDATE_OVERVIEW";
export const LOADING_LISTING_VEHICLE_UPDATE_LOCATION =
  "LOADING_LISTING_VEHICLE_UPDATE_LOCATION";
export const LOADING_LISTING_VEHICLE_UPDATE_FEATURES =
  "LOADING_LISTING_VEHICLE_UPDATE_FEATURES";
export const LOADING_LISTING_VEHICLE_UPDATE_DESCRIPTION =
  "LOADING_LISTING_VEHICLE_UPDATE_DESCRIPTION";
export const LOADING_LISTING_VEHICLE_APPROVED =
  "LOADING_LISTING_VEHICLE_APPROVED";
export const LOADING_LISTING_VEHICLE_DECLINE =
  "LOADING_LISTING_VEHICLE_DECLINE";
export const LOADING_LISTING_DELETE_IMAGES = "LOADING_LISTING_DELETE_IMAGES";
export const LOADING_LISTING_UPDATE_IMAGES = "LOADING_LISTING_UPDATE_IMAGES";
export const LOADING_LISTING_DELETE_LISTING = "LOADING_LISTING_DELETE_LISTING";
export const LISTING_DELETE_LISTING_SUCCESS = "LISTING_DELETE_LISTING_SUCCESS";
export const LOADING_LISTING_VEHICLE_UPDATE_MAKE_MODEL =
  "LOADING_LISTING_VEHICLE_UPDATE_MAKE_MODEL";
export const UPDATE_VEHICLE_MAKE_MODEL_SUCCESS =
  "UPDATE_VEHICLE_MAKE_MODEL_SUCCESS";
export const LISTING_ARCHIVE_SUCCESS = "LISTING_ARCHIVE_SUCCESS";
export const TOGGLE_LISTING_VEHICLE_TYPE = "TOGGLE_LISTING_VEHICLE_TYPE";
export const GET_TYPE_VEHICLES_LISTING = "GET_TYPE_VEHICLES_LISTING";
export const LISTING_DELETE_LISTING_SUCCESS_SINGLE =
  "LISTING_DELETE_LISTING_SUCCESS_SINGLE";
export const LOADING_LISTING_DELETE_LISTING_SINGLE =
  "LOADING_LISTING_DELETE_LISTING_SINGLE";
export const LOADING_EXPIRE_UPDATE_LISTING = "LOADING_EXPIRE_UPDATE_LISTING";
export const REACTIVE_EXPIRE_LISTING = "REACTIVE_EXPIRE_LISTING";
export const UNSELECT_DEALER_ACCOUNT_VEHICLE_LOCATION = "UNSELECT_DEALER_ACCOUNT_VEHICLE_LOCATION"
export const DELETE_DEALER_ACCOUNT_DOCUMENT_LOADING = "DELETE_DEALER_ACCOUNT_DOCUMENT_LOADING"
export const DEALER_ACCOUNT_VEHICLE_LOCATION = "DEALER_ACCOUNT_VEHICLE_LOCATION"
/***
 *
 * End Listing
 * Agents
 *
 */
export const LOADING_AGENTS = "LOADING_AGENTS";
export const GET_AGENTS = "GET_AGENTS";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const SINGLE_CHECK_AGENTS = "SINGLE_CHECK_AGENTS";
export const TOGGLE_ALL_CHECK_AGENTS = "TOGGLE_ALL_CHECK_AGENTS";
export const REMOVE_ALL_STATE_AGENTS = "REMOVE_ALL_STATE_AGENTS";
export const GET_AGENT_DETAIL = "GET_AGENT_DETAIL";
export const LOADING_DELETEING_AGENTS = "LOADING_DELETEING_AGENTS";
export const LOADING_UPDATE_AGENT_PERMISSION =
  "LOADING_UPDATE_AGENT_PERMISSION";
export const DELETE_SINGLE_AGENT = "DELETE_SINGLE_AGENT";
export const DELETE_MULTIPLE_AGENT = "DELETE_MULTIPLE_AGENT";
export const LOADING_UPDATE_AGENT_DETAIL = "LOADING_UPDATE_AGENT_DETAIL";
/***
 *
 * End Agents
 * Settings
 */
export const LOADING_ADMIN_UPDATE_PASSWORD = "LOADING_ADMIN_UPDATE_PASSWORD";
export const UPDATE_ADMIN_PASSWORD = "UPDATE_ADMIN_PASSWORD";
/**
 * Application
 *
 */
export const GET_PENDING_ACTIVE_APPLICATIONS =
  "GET_PENDING_ACTIVE_APPLICATIONS";
export const LOADING_ADMIN_APPLICATIONS = "LOADING_ADMIN_APPLICATIONS";
export const SINGLE_CHECK_APPLICATION = "SINGLE_CHECK_APPLICATION";
export const TOGGLE_ALL_CHECK_APPLICATION = "TOGGLE_ALL_CHECK_APPLICATION";
export const REMOVE_ALL_STATE_APPLICATION = "REMOVE_ALL_STATE_APPLICATION";
export const TOGGLE_VEHICLE_MAKE_EDIT_POST_APP_ADMIN =
  "TOGGLE_VEHICLE_MAKE_EDIT_POST_APP_ADMIN";
export const GET_VEHICLE_MAKES_EDIT_POST_APP_ADMIN =
  "GET_VEHICLE_MAKES_EDIT_POST_APP_ADMIN";
export const TOGGLE_VEHICLE_MODEL_EDIT_POST_APP_ADMIN =
  "TOGGLE_VEHICLE_MODEL_EDIT_POST_APP_ADMIN";
export const GET_VEHICLE_MODEL_EDIT_POST_APP_ADMIN =
  "GET_VEHICLE_MODEL_EDIT_POST_APP_ADMIN";
export const TOGGLE_VEHICLE_TRIMS_EDIT_POST_APP_ADMIN =
  "TOGGLE_VEHICLE_TRIMS_EDIT_POST_APP_ADMIN";
export const GET_VEHICLE_TRIMS_EDIT_POST_APP_ADMIN =
  "GET_VEHICLE_TRIMS_EDIT_POST_APP_ADMIN";
export const EDIT_POST_APP_LOADING_ADMIN = "EDIT_POST_APP_LOADING_ADMIN";
export const EDIT_POST_APP_UPDATE_ADMIN = "EDIT_POST_APP_UPDATE_ADMIN";
export const GET_ADDTIONAL_TYPE_ADMIN = "GET_ADDTIONAL_TYPE_ADMIN";
export const UPDATE_APPLICATION_ROW_DATA = "UPDATE_APPLICATION_ROW_DATA";
export const REMOVE_UPDATE_ROW_APPLICATION = "REMOVE_UPDATE_ROW_APPLICATION";
export const DELETE_ADDTIONAL_INFO_LOADING_ADMIN =
  "DELETE_ADDTIONAL_INFO_LOADING_ADMIN";
export const DELETE_ADDTIONAL_INFO_ADMIN = "DELETE_ADDTIONAL_INFO_ADMIN";
export const DELETE_VEHICLE_DETAIL_ADMIN = "DELETE_VEHICLE_DETAIL_ADMIN";
export const DELETE_VEHICLE_DETAIL_LOADING_ADMIN =
  "DELETE_VEHICLE_DETAIL_LOADING_ADMIN";
export const DELETE_UPLOADED_DOC_ADMIN = "DELETE_UPLOADED_DOC_ADMIN";
export const DELETE_UPLOAD_DOC_LOADING_ADMIN =
  "DELETE_UPLOAD_DOC_LOADING_ADMIN";
export const FLINKS_REQUEST_ID_RESPONSE_ADMIN =
  "FLINKS_REQUEST_ID_RESPONSE_ADMIN";
export const LOADING_FLINKS_REQUEST_ID_ADMIN =
  "LOADING_FLINKS_REQUEST_ID_ADMIN";
export const LOADING_FLINKS_LOGIN_ID_ADMIN = "LOADING_FLINKS_LOGIN_ID_ADMIN";
export const FLINKS_LOGIN_ID_RESPONSE_ADMIN = "FLINKS_LOGIN_ID_RESPONSE_ADMIN";
export const DELETE_SINGLE_APPLICATION = "DELETE_SINGLE_APPLICATION";
export const LOADING_DELETE_SINGLE_APPLICATION =
  "LOADING_DELETE_SINGLE_APPLICATION";
export const DELETE_MULTIPLE_APPLICATION = "DELETE_MULTIPLE_APPLICATION";
export const LOADING_ADMIN_EDIT_POST_STOCK_DETAIL =
  "LOADING_ADMIN_EDIT_POST_STOCK_DETAIL";
export const ADMIN_EDIT_POST_STOCK_DETAIL = "ADMIN_EDIT_POST_STOCK_DETAIL";
export const ADMIN_DELETE_COAPPLICANT_INFO = "ADMIN_DELETE_COAPPLICANT_INFO";
export const ADMIN_DELETE_COAPPLICANT_LOADING =
  "ADMIN_DELETE_COAPPLICANT_LOADING";
export const GET_CO_APPLICANT_CREDIT_REPORT_DATA =
  "GET_CO_APPLICANT_CREDIT_REPORT_DATA";
export const ADMIN_JUMIO_LOADING = "ADMIN_JUMIO_LOADING";
export const ADMIN_JUMIO_INFO = "ADMIN_JUMIO_INFO";
export const GET_AGENTS_APPLICATON = "GET_AGENTS_APPLICATON";
export const EDIT_ADMIN_POST_APP_UPDATE_UPLOAD_DOC =
  "EDIT_ADMIN_POST_APP_UPDATE_UPLOAD_DOC";
export const DELETE_LOAN_DOC = "DELETE_LOAN_DOC";
export const GET_PDF_DATA = "GET_PDF_DATA";
export const LOADING_CONTACT_US = "LOADING_CONTACT_US";
/**
 *
 * DMS
 */
export const GET_DMS_DETAIL = "GET_DMS_DETAIL";
export const REMOVE_ALL_STATE_DMS = "REMOVE_ALL_STATE_DMS";
export const LOADING_DMS_ACCOUNT = "LOADING_DMS_ACCOUNT";
export const GET_DMS_ACCOUNT_ROW = "GET_DMS_ACCOUNT_ROW";
export const REMOVE_UPDATE_ROW_DMS = "REMOVE_UPDATE_ROW_DMS";
export const GET_DMS_DEALER_DETAIL = "GET_DMS_DEALER_DETAIL";
export const GET_DMS_DEALER_FEED = "GET_DMS_DEALER_FEED";
export const UPDATE_DMS_ACCOUNT_LOADING = "UPDATE_DMS_ACCOUNT_LOADING";
export const SINGLE_DMS_CHECK = "SINGLE_DMS_CHECK";
export const TOGGLE_ALL_DMS_CHECK = "TOGGLE_ALL_DMS_CHECK";
export const LOADING_DELETE_SINGLE_DMS = "LOADING_DELETE_SINGLE_DMS";
export const DELETE_SINGLE_DMS = "DELETE_SINGLE_DMS";
export const DELETE_MULTIPLE_DMS = "DELETE_MULTIPLE_DMS";
export const SINGLE_DMS_DEALER_CHECK = "SINGLE_DMS_DEALER_CHECK";
export const TOGGLE_ALL_DMS_DEALER_CHECK = "TOGGLE_ALL_DMS_DEALER_CHECK";
export const DELETE_SINGLE_DMS_DEALER = "DELETE_SINGLE_DMS_DEALER";
export const DELETE_MULTIPLE_DMS_DEALER = "DELETE_MULTIPLE_DMS_DEALER";
/**
 *
 * DMS Lisings
 */
export const LOADING_LISTING_DMS = "LOADING_LISTING_DMS";
export const GET_LISTING_DMS = "GET_LISTING_DMS";
export const SINGLE_CHECK_LISTING_DMS = "SINGLE_CHECK_LISTING_DMS";
export const TOGGLE_ALL_CHECK_LISTING_DMS = "TOGGLE_ALL_CHECK_LISTING_DMS";
export const REMOVE_ALL_STATE_LISTING_DMS = "REMOVE_ALL_STATE_LISTING_DMS";
export const GET_LISTING_DETAIL_DMS = "GET_LISTING_DETAIL_DMS";
export const LOADING_LISTING_DETAIL_DMS = "LOADING_LISTING_DETAIL_DMS";
export const REMOVE_DETAIL_EDIT_DMS = "REMOVE_DETAIL_EDIT_DMS";
export const TOGGLE_VEHICLE_MODEL_DMS = "TOGGLE_VEHICLE_MODEL_DMS";
export const GET_VEHICLE_MODEL_DMS = "GET_VEHICLE_MODEL_DMS";
export const TOGGLE_VEHICLE_TYPE_DMS = "TOGGLE_VEHICLE_TYPE_DMS";
export const GET_TYPE_VEHICLES_DMS = "GET_TYPE_VEHICLES_DMS";
export const TOGGLE_VEHICLE_MAKE_DMS = "TOGGLE_VEHICLE_MAKE_DMS";
export const GET_VEHICLE_MAKES_DMS = "GET_VEHICLE_MAKES_DMS";
export const TOGGLE_VEHICLE_TRIMS_DMS = "TOGGLE_VEHICLE_TRIMS_DMS";
export const GET_VEHICLE_TRIMS_DMS = "GET_VEHICLE_TRIMS_DMS";
export const GET_VEHICLE_BODY_TYPE_DMS = "GET_VEHICLE_BODY_TYPE_DMS";
export const GET_VEHICLE_FUEL_TYPE_DMS = "GET_VEHICLE_FUEL_TYPE_DMS";
export const GET_VEHICLE_DRIVE_TRAIN_DMS = "GET_VEHICLE_DRIVE_TRAIN_DMS";
export const GET_VEHICLE_FEATURES_DMS = "GET_VEHICLE_FEATURES_DMS";
export const REMOVE_ALL_DMS = "REMOVE_ALL_DMS";
export const TOGGLE_VEHICLE_FEATURES_DMS = "TOGGLE_VEHICLE_FEATURES_DMS";
export const LOADING_LISTING_VEHICLE_UPDATE_DMS =
  "LOADING_LISTING_VEHICLE_UPDATE_DMS";
export const LOADING_LISTING_VEHICLE_UPDATE_OVERVIEW_DMS =
  "LOADING_LISTING_VEHICLE_UPDATE_OVERVIEW_DMS";
export const LOADING_LISTING_VEHICLE_UPDATE_LOCATION_DMS =
  "LOADING_LISTING_VEHICLE_UPDATE_LOCATION_DMS";
export const LOADING_LISTING_VEHICLE_UPDATE_FEATURES_DMS =
  "LOADING_LISTING_VEHICLE_UPDATE_FEATURES_DMS";
export const LOADING_LISTING_VEHICLE_UPDATE_DESCRIPTION_DMS =
  "LOADING_LISTING_VEHICLE_UPDATE_DESCRIPTION_DMS";
export const LOADING_LISTING_VEHICLE_APPROVED_DMS =
  "LOADING_LISTING_VEHICLE_APPROVED_DMS";
export const LOADING_LISTING_VEHICLE_DECLINE_DMS =
  "LOADING_LISTING_VEHICLE_DECLINE_DMS";
export const LOADING_LISTING_DELETE_IMAGES_DMS =
  "LOADING_LISTING_DELETE_IMAGES_DMS";
export const LOADING_LISTING_UPDATE_IMAGES_DMS =
  "LOADING_LISTING_UPDATE_IMAGES_DMS";
export const LOADING_LISTING_DELETE_LISTING_DMS =
  "LOADING_LISTING_DELETE_LISTING_DMS";
export const LISTING_DELETE_LISTING_SUCCESS_DMS =
  "LISTING_DELETE_LISTING_SUCCESS_DMS";
export const LOADING_LISTING_VEHICLE_UPDATE_MAKE_MODEL_DMS =
  "LOADING_LISTING_VEHICLE_UPDATE_MAKE_MODEL_DMS";
export const UPDATE_VEHICLE_MAKE_MODEL_SUCCESS_DMS =
  "UPDATE_VEHICLE_MAKE_MODEL_SUCCESS_DMS";
export const LISTING_ARCHIVE_SUCCESS_DMS = "LISTING_ARCHIVE_SUCCESS_DMS";
export const LOADING_ADMIN_APIS_DMS = "LOADING_ADMIN_APIS_DMS";
export const LISTING_DELETE_LISTING_SUCCESS_SINGLE_DMS =
  "LISTING_DELETE_LISTING_SUCCESS_SINGLE_DMS";
export const LOADING_LISTING_DELETE_LISTING_SINGLE_DMS =
  "LOADING_LISTING_DELETE_LISTING_SINGLE_DMS";
export const LOADING_EXPIRE_UPDATE_LISTING_DMS =
  "LOADING_EXPIRE_UPDATE_LISTING_DMS";
export const REACTIVE_EXPIRE_LISTING_DMS = "REACTIVE_EXPIRE_LISTING_DMS";
