import $ from "jquery";
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import ApplicationHeaderAdmin from "../ApplicationHeaderAdmin";
import { API_URL } from "../../../../constant";
import { toastr } from "react-redux-toastr";
import axios from 'axios';

const validateForm = (obj_validating_keys) => {
  let is_valid = true;
  Object.values(obj_validating_keys).forEach((value) => {
    if (value === false) {
      is_valid = false;
    }
  });
  return is_valid;
};

class AssetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenName: "assets-detail",
      vehicleType: "",
      subTypeOfVehicle: "",
      vehicleOptions: [],
      subTypeOptions: [],
      selectedVehicle: null,
      selectedSubType: null,
      validation: {
        vehicleType: true,
        subTypeOfVehicle: true
      },
      errors: {
        vehicleType: '',
        subTypeOfVehicle: ''
      }
    };
    $("#assets-detail").removeClass("tabDeactive");

    this.vehicles = [
      { title: "Automotive", name: "automotive", value: false, subTypes: [{ title: "Truck", name: "truck", value: false }, { title: "SUV", name: "suv", value: false }, { title: "Van", name: "van", value: false }, { title: "Cars", name: "cars", value: false }] },
      { title: "Powersport", name: "powersport", value: false, subTypes: [{ title: "Snowmobile", name: "snowmobile", value: false }, { title: "ATV/UTV", name: "atv/utv", value: false }, { title: "Motorcycle", name: "motorcycle", value: false }, { title: "Dirt Bike", name: "dirt_bike", value: false }, { title: "E-Scooter", name: "e-scooter", value: false }] },
      { title: "Marine", name: "marine", value: false, subTypes: [{ title: "Personal Watercarft", name: "personal_watercarft", value: false }, { title: "Boat", name: "boat", value: false }] },
      { title: "RV", name: "rv", value: false, subTypes: [{ title: "Motor Home", name: "motor_home", value: false }, { title: "Travel Trailer", name: "travel_trailer", value: false }] },
      { title: "Trailer", name: "trailer", value: false, subTypes: [{ title: "Enclosed", name: "enclosed", value: false },{ title: "Utility", name: "Utility", value: false }] },
      { title: "Retail", name: "retail", value: false, subTypes: [{ title: "Lawn Tractor", name: "lawn_tractor", value: false },{ title: "Small Equipment", name: "small_equipment", value: false }] },
      { title: "Construction", name: "construction", value: false, subTypes: [{ title: "Skid Steer", name: "skid_steer", value: false }, { title: "Backhoe", name: "backhoe", value: false }, { title: "Mini Excavator", name: "mini_excavator", value: false }, { title: "Loader", name: "loader", value: false }, { title: "Farm Tractor", name: "farm_tractor", value: false }, { title: "Small Construction", name: "small_construction", value: false }] }
    ];

    this.state.vehicleOptions = this.vehicles.map(vehicle => ({ label: vehicle.title, value: vehicle.name }));

    this.handleSubmit = this.handleSubmit.bind(this);  // Ensure handleSubmit is bound
  }

  componentDidMount() {
    const applicationId = this.props.match.params.id;
    axios.get(`${API_URL}/applynow-applications/application/${applicationId}/get-asset-detail/`)
      .then((res) => {
        if (res.data.data && res.data.data.vehicle_type && res.data.data.sub_vehicle_type) {
          const selectedVehicle = this.state.vehicleOptions.find(option => option.label === res.data.data.vehicle_type);
          const subTypes = this.vehicles.find(vehicle => vehicle.name === selectedVehicle.value).subTypes;
          const selectedSubType = subTypes.find(subType => subType.title === res.data.data.sub_vehicle_type);

          // Convert the selectedSubType to the format expected by the Select component
          const formattedSelectedSubType = { label: selectedSubType.title, value: selectedSubType.name };

          this.setState({
            selectedVehicle,
            subTypeOptions: subTypes.map(subType => ({ label: subType.title, value: subType.name })),
            selectedSubType: formattedSelectedSubType,
          }, () => {
            console.log('Selected vehicle:', this.state.selectedVehicle);
            console.log('Subtypes:', this.state.subTypeOptions);
            console.log('Selected subtype:', this.state.selectedSubType);
          });
        }
      })
      .catch((error) => {
        console.error("Failed to fetch asset details", error);
      });
  }

  handleVehicleChange = (selectedVehicle) => {
    const subTypes = this.vehicles.find(vehicle => vehicle.name === selectedVehicle.value).subTypes;
    this.setState({
      selectedVehicle,
      subTypeOptions: subTypes.map(subType => ({ label: subType.title, value: subType.name })),
      selectedSubType: null,
    });
  }

  handleSubTypeChange = (selectedSubType) => {
    this.setState({ selectedSubType });
  }

  validateOnChangeAssetDetail = (field, value) => {
    let { validation, errors } = this.state;
    switch (field) {
      case 'vehicleType':
        validation.vehicleType = value ? true : false;
        errors.vehicleType = value ? '' : 'Vehicle type is required';
        break;
      case 'subTypeOfVehicle':
        validation.subTypeOfVehicle = value ? true : false;
        errors.subTypeOfVehicle = value ? '' : 'Subtype of vehicle is required';
        break;
      default:
        break;
    }
    this.setState({ validation, errors });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('handleSubmit called');  // Logging for debugging

    this.validateOnChangeAssetDetail('vehicleType', this.state.selectedVehicle ? this.state.selectedVehicle.label : '');
    this.validateOnChangeAssetDetail('subTypeOfVehicle', this.state.selectedSubType ? this.state.selectedSubType.label : '');

    if (validateForm(this.state.validation)) {
      const payload = {
        vehicle_type: this.state.selectedVehicle ? this.state.selectedVehicle.label : '',
        sub_vehicle_type: this.state.selectedSubType ? this.state.selectedSubType.label : '',
      };

      const applicationId = this.props.match.params.id;
      axios.put(`${API_URL}/applynow-applications/application/${applicationId}/update-asset-detail`, payload)
        .then((res) => {
          toastr.success("", "Details successfully updated");
        })
        .catch((error) => {
          toastr.error("", "Failed to update details");
          console.error("Error updating asset details", error);  // Logging for debugging
        });
    } else {
      console.log('Form validation failed', this.state.validation);  // Logging for debugging
    }
  }

  render() {
    const renderOption = (props) => {
      return (
        <components.Option {...props}>
          <div>{props.data.label}</div>
        </components.Option>
      );
    };

    const renderScrollbar = (props) => (
      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={300}
      >
        {props.children}
      </Scrollbars>
    );    


    const renderOptionSubType = (props) => {
      return (
        <components.Option {...props}>
          <div>{props.data.label}</div>
        </components.Option>
      );
    };

    const renderScrollbarSub = (props) => (
      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={260}
      >
        {props.children}
      </Scrollbars>
    );    

    const applicationId = this.props.match.params.id;

    return (
      <>
        <div className="app-form-content">
          <div className="app-form-content-inner">
            <ApplicationHeaderAdmin {...this.props} />

            <ul
              className="nav nav-tabs-2 admin-tabs three-items text-left"
              id="formAppTabs"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active pl-5"
                  id="delivery-verification-tab"
                  data-toggle="tab"
                  href="#assets-detail"
                  role="tab"
                  aria-controls="assets-detail"
                >
                  <span className="tabs-text delivery-verification">
                    Asset Detail
                  </span>
                </a>
              </li>
            </ul>

            <div className="tab-content" id="formAppTabsContent">
              <div
                className="tab-pane fade show active clearfix"
                id="assets-detail"
                role="tabpanel"
                aria-labelledby="assets-detail-tab"
              >
                {this.state.screenName === "assets-detail" ? (
                  <React.Fragment>
                    <div className="forms-head clearfix">
                      <h1> Asset Detail </h1>
                    </div>

                    <div className="form-main">
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> Type of vehicle<em>*</em></label>
                          <Select
                            placeholder="Search Vehicle"
                            id="selectedVehicle"
                            name="vehicleType"
                            value={this.state.selectedVehicle}
                            onChange={this.handleVehicleChange}
                            options={this.state.vehicleOptions}
                            isSearchable
                            isClearable
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOption,
                              MenuList: renderScrollbar,
                            }}
                            captureMenuScroll={false}
                          />
                          <span className="text-danger">{this.state.errors.vehicleType}</span>
                        </div>

                        <div className="form-field-col">
                          <label>Subtype of vehicle<em>*</em></label>
                          <Select
                            placeholder="Subtype of vehicle"
                            id="selectedSubTypeOfVehicle"
                            name="subTypeOfVehicle"
                            value={this.state.selectedSubType}
                            onChange={this.handleSubTypeChange}
                            options={this.state.subTypeOptions}
                            isSearchable
                            isClearable
                            className="react-select-main"
                            classNamePrefix="react-select"
                            components={{
                              Option: renderOptionSubType,
                              MenuList: renderScrollbarSub,
                            }}
                            captureMenuScroll={false}
                          />
                          <span className="text-danger">{this.state.errors.subTypeOfVehicle}</span>
                        </div>
                      </div>
                    </div>

                    <div className="footer-btns-holder clearfix">
                      <Link to={`/admin/applynow-application/${applicationId}/applicant-detail/`}>
                        <button
                          className="btn btn-primary float-left"
                          onClick={() => this.props.onClickChangeStep(3, 2)}
                        >
                          Back
                        </button>
                      </Link>
                      {this.props.loading_update === true ? (
                        <button className="btn btn-primary float-right active">
                          <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary float-right active"
                          onClick={(event) => this.handleSubmit(event)}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AssetDetail;
