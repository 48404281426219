import axios from "axios";
import { API_URL } from '../constant';
import { history } from './history';
import * as ACTIONS from '../actions/authActions';
import Store from './store';

// Create an axios instance with the base URL
const authInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true // Ensure credentials (cookies) are sent with requests
});

// Request interceptor (Authorization header is removed since we rely on cookies)
authInstance.interceptors.request.use(
    config => { return config; },
    error => Promise.reject(error)
);

// Response interceptor to handle token refresh logic
authInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Attempt to refresh the token via the refresh endpoint
                const res = await axios.post(`${API_URL}/token/refresh/`, {}, { withCredentials: true });

                if (res?.status === 201 || res?.status === 200) {
                    // Assuming the backend sets a new access token in the HttpOnly cookie
                    return authInstance(originalRequest); // Retry the original request
                } else {
                    throw new Error('Failed to refresh token');
                }
            } catch (err) {
                Store.dispatch(ACTIONS.logout_session());
                history.push('/login');
                return Promise.reject(err);
            }
        } else if (error?.response?.status === 403 && originalRequest.url === `${API_URL}/token/`) {
            history.push('/login');
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

export default authInstance;
