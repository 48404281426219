import {

  USERS_LOGIN_REQUEST,
  USERS_LOGIN_SUCCESS,
  ADMIN_LOGIN_SUCCESS,
  USERS_LOGIN_FAILURE,
  USERS_LOGOUT,

  REGISTER_REQUEST,
  USERS_REGISTER_SUCCESS,
  USERS_REGISTER_SUCCESS_POST_APP,
  REGISTER_FAILURE,

  VERIFY_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  VERIFY_LOGIN_USER,

  ADMIN_LOGOUT,
  ADMIN_SCREENS,

  USER_FORGOT_PASSWORD_LOADING,
  RESET_EMAIL_SENT,
  RESET_PASSWORD_ERROR,

  REMOVE_ALL_PROFILE_DETAIL,

  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_FAILURE,

  OTP_SEND_START,
  OTP_SEND_SUCCESS,
  OTP_SEND_FAILURE,

  DEALER_REGISTER,

  IS_LOADING_TRUE,
  IS_LOADING_FALSE,
  CHANGE_OTP_PHONE_NO
} from "../_constants/constants";

import { history } from "../_helpers";
import { toastr } from "react-redux-toastr";
import simpleAxios from "../_helpers/axios";
import axios from '../_helpers/axiosInterceptorsAdmin'
import { API_URL, USER_TYPE } from '../constant';

import jwt_decode from "jwt-decode";
import {
  get_user_profile_data,
  get_dealer_profile_data,
} from "./userProfileActions";
import { change_autoComplete_lng_lat, change_map_lng_lat } from "./homeActions";

const cleanLocalStorage = () => {
  const keysToRemove = [
    "user",
    "user_type",
    "user_alias",
    "lastStepPostAppEditAdmin",
    "coApplicantEditPostAppAdmin",
    "lastStepPostAppEditAdmin",
    "coApplicantEditPostAppAdmin",
    "lastStepPostAppEdit",
    "coApplicantEditPostApp",
    "lastStepDealerPostAppEdit",
    "coApplicantDealerEditPostApp",
    "userId",
    "is_staff",
    "staff_dealer",
    "dealerSccrens",
    "admin_user_type",
    "admin",
    "adminSccrens",
    "lastAdminStepPostAppEdit",
    "coAdminApplicantEditPostApp",
    "adminId",
    "app_tab"
  ];

  keysToRemove.forEach(key => {
    localStorage.removeItem(key);
  });
};

const expireTokens = async () => {
  try {
      const response = await axios.post(`${API_URL}/token/expire/`, {}, {
          withCredentials: true, // Ensure cookies are sent with the request
      });

      if (response.status === 200) {
          console.log('Tokens expired successfully');
      }
  } catch (error) {
      console.error('Error expiring tokens:', error.response ? error.response.data : error.message);
  }
};


export const register = (data, isFromModalView = false, callback) => {
  return (dispatch) => {
    cleanLocalStorage();
    dispatch({ type: REGISTER_REQUEST });

    const url = isFromModalView ? "/check-mail/" : "/buyer_details/create/";
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(isFromModalView ? { email: data?.email } : { ...data, is_active: true }),
      url,
    };

    simpleAxios(options)
      .then((response) => {
        if (response.data.success) {
          if (isFromModalView) {
            callback && callback();
            dispatch({ type: USERS_REGISTER_SUCCESS, response: data });
          } else {
            dispatch({ type: VERIFY_USER_SUCCESS, response: response.data.data });
          }
        } else {
          dispatch({ type: REGISTER_FAILURE });
          toastr.error("Error", response.data.message.toString());
        }
      })
      .catch((err) => {
        const message = err.response?.data?.message?.password?.[0] || err.response?.data?.message || err.message;
        dispatch({ type: REGISTER_FAILURE });
        toastr.error("Error", message.toString());
      });
  };
};

/***
 * Sign Up Post App
 */
const handleError = (err, dispatch, failureType) => {
  const message = err.response?.data?.message?.password?.[0] || err.response?.data?.message || err.message;
  dispatch({ type: failureType });
  toastr.error("Error", message.toString());
};

export const postAppRegister = (data, verifyData) => {
  return async (dispatch) => {
    try {
      cleanLocalStorage();
      dispatch({ type: REGISTER_REQUEST });

      const response = await simpleAxios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(data),
        url: "/buyer_details/create/",
      });

      if (response.data.success) {
        dispatch({
          type: USERS_REGISTER_SUCCESS_POST_APP,
          response: response.data.data,
        });
        dispatch(verify_user(verifyData));
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      handleError(err, dispatch, REGISTER_FAILURE);
    }
  };
};

export const verify_user = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: VERIFY_REQUEST });

      const response = await simpleAxios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(data),
        url: "/email-verify/",
      });

      if (response.data.success) {
        dispatch({
          type: VERIFY_USER_SUCCESS,
          response: response.data.data,
        });

        const registrationState = getState().authReducer.registration?.user_detail;

        const createResponse = await simpleAxios({
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify({
            ...registrationState,
            is_active: true,
            no_email: true,
          }),
          url: "/buyer_details/create/",
        });

        if (createResponse.data.success) {
          dispatch({
            type: USERS_REGISTER_SUCCESS,
            response: createResponse.data.data,
          });

          if (callback) callback();

          const loginData = {
            email: registrationState.email,
            password: registrationState.password,
          };
          dispatch(login_post_app(loginData));
        } else {
          throw new Error(createResponse.data.message);
        }

      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      handleError(err, dispatch, VERIFY_USER_FAILURE);
    }
  };
};

// Action to validate the session
export const validate_session = (role) => {
  return async dispatch => {
    try {
      const response = await axios.post(`${API_URL}/token/verify/`, {});

      if (response.status === 200) {
        return response;
      } else {
        throw new Error('Session validation failed');
      }
    } catch (error) {
      if (role === 'admin') {
        dispatch(logout_session_admin());
      } else {
        dispatch(logout_session());
      }
      throw error;
    }
  };
};

export const send_otp = (data, resendOTP = false, callback) => {
  return async (dispatch) => {
    try {
      if (!resendOTP) dispatch({ type: OTP_SEND_START });

      const response = await simpleAxios.post("/send-otp/", data, {
        headers: { "Content-Type": "application/json" },
      });

      dispatch({ type: OTP_SEND_SUCCESS });

      if (response.data.success) {
        toastr.success("Success", resendOTP ? "OTP resent" : response.data.message.toString());
        if (callback) callback();
      } else {
        toastr.error("Error", resendOTP ? "OTP not resent" : response.data.message.toString());
      }
    } catch (err) {
      const message = err.response?.data?.message || err.message || "Error in sending OTP, please try later!";
      dispatch({ type: OTP_SEND_FAILURE });
      toastr.error("Error", message.toString());
    }
  };
};


export const verify_otp_simple = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: VERIFY_REQUEST });

      const response = await simpleAxios.post("/verify-otp/", data, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        dispatch({ type: VERIFY_USER_SUCCESS, response: response.data.data });
        if (callback) callback();
      } else {
        toastr.error("Error", response.data.message.toString());
      }
    } catch (err) {
      const message = err.response?.data?.message || err.message || "Error in verifying OTP, please try later!";
      dispatch({ type: VERIFY_USER_FAILURE });
      toastr.error("Error", message.toString());
    }
  };
};

export const verify_otp = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: VERIFY_REQUEST });

      const state = getState();
      const response = await simpleAxios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(data),
        url: "/verify-otp/",
      });

      if (response.data.success) {
        const registrationState = state.authReducer.registration?.user_detail;
        const registrationResponse = await simpleAxios({
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify({
            ...registrationState,
            is_active: true,
            no_email: true,
            is_verified: true,
            phone_number: data.phone,
          }),
          url: "/buyer_details/create/",
        });

        if (registrationResponse.data.success) {
          dispatch({ type: CHANGE_OTP_PHONE_NO });

          dispatch({
            type: VERIFY_USER_SUCCESS,
            response: response.data.data,
          });

          dispatch({
            type: USERS_REGISTER_SUCCESS,
            response: registrationResponse.data.data,
          });

          const loginData = registrationResponse.data.data;
          const decoded = jwt_decode(loginData.data.access);

          dispatch({
            type: USERS_LOGIN_SUCCESS,
            user: decoded,
          });

          localStorage.setItem("user_type", loginData.data.user_type);
          localStorage.setItem("user", JSON.stringify(loginData.data));
          localStorage.setItem("userId", loginData.data.id || "");

          if (Number(loginData.data.user_type) === USER_TYPE.DEALER_USER) {
            dispatch(get_dealer_profile_data(loginData.data.id));
          } else {
            dispatch(get_user_profile_data(loginData.data.id));
          }

          if (callback) callback();
        } else {
          handleRegistrationError(dispatch, registrationResponse.data.message);
        }

        // updateDatabase(response.data.data);  # chat deleted
      } else {
        handleError({ response: { data: { message: response.data.message } } }, dispatch, VERIFY_USER_FAILURE);
      }
    } catch (err) {
      handleError(err, dispatch, VERIFY_USER_FAILURE);
    }
  };
};

const handleRegistrationError = (dispatch, message) => {
  dispatch({ type: REGISTER_FAILURE });
  toastr.error("Error", message);
};

export const verify_otp_dealer = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: VERIFY_REQUEST });
      dispatch({ type: IS_LOADING_TRUE });

      const response = await simpleAxios.post("/verify-otp/", data, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        const state = getState();
        const registrationState = state.authReducer.registration?.dealer_data;
        const dealerPreference = state.authReducer.registration?.dealer_preference;

        registrationState.append("phone_number", data.phone);

        const createResponse = await simpleAxios.post("/dealer_details/create/", registrationState, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (createResponse.data.success) {
          if (dealerPreference.unmanaged) {
            dispatch(add_unmanaged_dealer({ ...dealerPreference, user_id: createResponse.data.user_id }));
          }

          dispatch({ type: VERIFY_USER_SUCCESS, response: response.data.data });
          dispatch({ type: DEALER_REGISTER, response: createResponse.data.data });
          dispatch({ type: IS_LOADING_FALSE });

          if (callback) callback();
        } else {
          throw new Error(createResponse.data.message);
        }

      } else {
        toastr.error("Error", response.data.message.toString());
      }
    } catch (err) {
      const message = err.response?.data?.message || err.message || "Error in verifying OTP, please try later!";
      dispatch({ type: VERIFY_USER_FAILURE });
      dispatch({ type: IS_LOADING_FALSE });
      toastr.error("Error", message.toString());
    }
  };
};


export const add_unmanaged_dealer = (data) => {
  return async (dispatch) => {
    try {
      const response = await simpleAxios.post("/makeunmanaged/", data, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        toastr.success("Success", response.data.message.toString());
      } else {
        toastr.error("Error", response.data.message.toString());
      }
    } catch (err) {
      const message = err.response?.data?.message || err.message || "Error in Unmanaged Dealer";
      toastr.error("Error", message.toString());
    }
  };
};

export const resend_email = (data) => {
  return async (dispatch) => {
    try {
      const response = await simpleAxios.post("/request-reset-email/", data, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.success) {
        toastr.success("Success", response.data.message.toString());
      } else {
        toastr.error("Error", response.data.message.toString());
      }
    } catch (err) {
      const message = err.response?.data?.message || err.message || "Error in resending email";
      toastr.error("Error", message.toString());
    }
  };
};

export const login = (data) => {

  return (dispatch) => {

    cleanLocalStorage();
    
    dispatch({ type: USERS_LOGIN_REQUEST });

    const url = `${API_URL}/token/`;

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf8' },
      data: JSON.stringify(data),
      url,
    };

    axios(options)

      .then((response) => {

        const { data: _data, success, message } = response.data;
        const { id: user_id, dealer_id, user_type, is_staff, is_verified, user_alias } = _data || {};

        if (success && _data && Number(user_type) === USER_TYPE.DEALER_USER) {

          localStorage.setItem('user_type', user_type);
          localStorage.setItem('user', JSON.stringify(_data));
          localStorage.setItem('userId', user_id);
          localStorage.setItem('is_staff', is_staff);
          localStorage.setItem('user_alias', user_alias);

          // Dispatch login success
          dispatch({ type: USERS_LOGIN_SUCCESS, user: _data });

          // Fetch dealer profile and navigate to the dealer admin page
          dispatch(get_dealer_profile_data(user_id));
          history.push('/dealer-admin/application/general');

        } else {
          // Login failed, handle the failure case
          dispatch({ type: USERS_LOGIN_FAILURE });
    
          // Show error message from the backend or a default one
          toastr.error('Please enter valid dealer role credentials.');
          
          // Expire tokens if login fails
          expireTokens();
        }

      })

      .catch((err) => {

        const message =
          err.response?.data?.message ||
          err.response?.data?.detail ||
          (typeof err.response?.data?.errors === 'string' && err.response.data.errors) ||
          (typeof err.response?.data?.errors === 'object' && Object.values(err.response.data.errors).flat().join(', ')) ||
          err.response?.statusText ||
          err.message ||
          'An error occurred. Please try again.';
      
        dispatch({ type: USERS_LOGIN_FAILURE });
        toastr.error(message);
        expireTokens();
      });
  };
};

export const login_post_app = (data) => {

  return async (dispatch) => {

    try {

      cleanLocalStorage();
      dispatch({ type: USERS_LOGIN_REQUEST });

      const response = await simpleAxios({
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf8" },
        data: JSON.stringify(data),
        url: "/token/",
      });

      const responseData = response.data;

      if (responseData.success && responseData.data && !responseData.data.is_staff) {
        const user = jwt_decode(responseData.data.access);

        dispatch({
          type: USERS_LOGIN_SUCCESS,
          user,
        });

        localStorage.setItem("user_type", responseData.data.user_type);
        localStorage.setItem("user", JSON.stringify(responseData.data));
        localStorage.setItem("userId", responseData.data.id || "");

        if (Number(responseData.data.user_type) === USER_TYPE.DEALER_USER) {
          dispatch(get_dealer_profile_data(responseData.data.id));
        } else {
          dispatch(get_user_profile_data(responseData.data.id));
        }
      } else if (responseData.success && responseData.data?.is_staff) {
        dispatch({ type: USERS_LOGIN_FAILURE });
        toastr.error("Please Enter Valid Credentials");
      } else if (responseData.success === false && responseData.data?.is_verified === false) {
        dispatch({ type: VERIFY_LOGIN_USER });
        toastr.error(responseData.message.toString());
      } else {
        dispatch({ type: USERS_LOGIN_FAILURE });
        toastr.error(responseData.message.toString());
      }
    } catch (err) {
      const message = err.response?.status === 500
        ? err.response.statusText
        : err.response?.data?.message || err.message;

      dispatch({ type: USERS_LOGIN_FAILURE });
      toastr.error(message.toString());
    }
  };
};

function isEmpty(value) {
  // Check if the value is null, undefined, an empty string, or an empty object or array
  return value === null || value === undefined ||
    (typeof value === 'string' && value.trim() === '') ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0);
}

export const admin_login = (data) => {
  return (dispatch) => {
    cleanLocalStorage();
    dispatch({ type: ADMIN_LOGIN_REQUEST });

    const url = `/token/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf8" },
      data: JSON.stringify(data),
      url,
      withCredentials: true
    };

    simpleAxios(options)
      .then((response) => {
        const { data: responseData, success } = response.data;

        const {
          id: user_id = "",
          is_staff = false,
          dealer_id = "",
          user_type = "",
          is_verified = false,
        } = responseData || {};

        if (success) {
          if (is_staff && !isEmpty(user_id)) {
            if (isEmpty(dealer_id) && ![USER_TYPE.GENERAL_USER, USER_TYPE.DEALER_USER].includes(+user_type)) {

              localStorage.setItem("admin_user_type", user_type);
              localStorage.setItem("admin", JSON.stringify(responseData));
              localStorage.setItem("adminId", user_id);
              localStorage.setItem("userId", user_id);

              // At this point, assume the tokens are being handled via cookies, no need to manually decode
              dispatch({
                type: ADMIN_LOGIN_SUCCESS,
                admin: {
                  user_id,
                  user_type,
                  email: responseData.email,
                  is_verified,
                  is_staff
                }
              });
              dispatch(get_agents_screens(user_id));

            } else {
              dispatch({ type: ADMIN_LOGIN_FAILURE });
              toastr.error("Please Enter Valid Credentials");
            }
          } else if (!is_staff) {
            dispatch({ type: ADMIN_LOGIN_FAILURE });
            toastr.error("Please Enter Valid Credentials");
            expireTokens();
          } else {
            if (!is_verified) {
              dispatch({ type: VERIFY_LOGIN_USER });
            } else {
              dispatch({ type: ADMIN_LOGIN_FAILURE });
            }
            toastr.error(response.data.message.toString());
          }
        } else {
          dispatch({ type: ADMIN_LOGIN_FAILURE });
          toastr.error(response.data.message.toString());
          expireTokens();
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          err.response?.data?.detail ||
          (typeof err.response?.data?.errors === 'string' && err.response.data.errors) ||
          (typeof err.response?.data?.errors === 'object' && Object.values(err.response.data.errors).flat().join(', ')) ||
          err.response?.statusText ||
          err.message ||
          'An error occurred. Please try again.';
      
        dispatch({ type: USERS_LOGIN_FAILURE });
        toastr.error(message);
        expireTokens();
      });
  };
};


/**
 * - get token and refresh token
 * - get user detail including below:
 *    - full_name
 *    - email
 *    - id
 *    - user_type
 *    - is_activate
 *    - is_verified
 *    - is_staff
 *    - dealer_id
*/

/**
 * Below are listed all the actions that are used in user login
 * 
 *  
 *  dispatch({
      type: ADMIN_LOGIN_REQUEST,
    });
    {
      isLoading: true, 
      userVerified: true, 
      adminLogin: false
    }
 * 
 *  dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      admin: data,
    });
    {
      isLoading: false, 
      userVerified: true, 
      adminLogin: true,
      admin: action.admin
    }
 * 
 *  var decoded = jwt_decode(loginData.data.access);
    dispatch({
      type: USERS_LOGIN_SUCCESS,
      user: decoded,
    });
    {
      isLoading: false,
      userVerified: true
      loggedIn: true,
      user: action.user,
    }    
 *
 *  dispatch(dealer_login_with_phone(loginData))
 *  
 *  dispatch({
      type: ADMIN_LOGIN_FAILURE,
    });
    {
      isLoading: false,
      userVerified: true,
      adminLogin: false,
      admin: {}
    }
 */

/**
 *  description:
 *  - dispatch USERS_LOGIN_REQUEST
 *  - request to get token, refresh token and user detail
 *  - response object:
 *  {
      access: "string",
      refresh: "string",
      user: "ft_testing_one ft_testing_one",
      email: "prasherhere@gmail.com",
      id: 6286,
      user_type: 1,
      is_active: true,
      is_verified: false,
      is_staff: false,
      dealer_id: null
    }
 * - if success then dispatch ADMIN_LOGIN_SUCCESS
 * - if error then dispatch ADMIN_LOGIN_FAILURE
 */

export const user_login = (data) => {
  return (dispatch) => {
    cleanLocalStorage();

    dispatch({
      type: USERS_LOGIN_REQUEST,
    });

    const url = `/token/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf8" },
      data: JSON.stringify(data),
      url,
      withCredentials: true
    };

    simpleAxios(options)
      .then((response) => {
        const { data: _data, message, success: is_success } = response.data;
        const { id: user_id, is_staff, user_type } = _data || {};

        if (Number(user_type) !== USER_TYPE.GENERAL_USER) {
          
          dispatch({
            type: USERS_LOGIN_FAILURE,
          });
          toastr.error("Please Enter Valid Credentials");          
          expireTokens();

        }else{

          if (is_success && user_id && !isNaN(user_id) && !is_staff) {

            dispatch({
              type: USERS_LOGIN_SUCCESS,
              user: _data,
            });

            localStorage.setItem("user_type", user_type);
            localStorage.setItem("user", JSON.stringify(_data));
            localStorage.setItem("userId", user_id);

            if (Number(user_type) === USER_TYPE.DEALER_USER) {
              dispatch(get_dealer_profile_data(user_id));
            } else {
              dispatch(get_user_profile_data(user_id));
            }

            const redirectTo = (history.location.state?.prevLocation || "/");
            history.push(redirectTo);
          } else if (is_success && user_id && !isNaN(user_id) && is_staff) {
            dispatch({
              type: USERS_LOGIN_FAILURE,
            });
            toastr.error("Please Enter Valid Credentials");
          } else {
            const is_verified = _data?.is_verified;
            if (!is_success && !is_verified) {
              dispatch({
                type: VERIFY_LOGIN_USER,
              });
              toastr.error(message || "An error occurred.");
            } else {
              dispatch({
                type: USERS_LOGIN_FAILURE,
              });
              toastr.error(message || "An error occurred.");
            }
          }

        }

      })
      .catch((error) => {
        const message =
          error.response?.data?.message ||
          error.response?.data?.detail ||
          error.message ||
          'An error occurred. Please try again.';

        dispatch({
          type: USERS_LOGIN_FAILURE,
        });
        toastr.error(message);
        expireTokens();
      });
  };
};


export const forgot_password = (data) => {
  return (dispatch) => {
    cleanLocalStorage();

    dispatch({ type: USER_FORGOT_PASSWORD_LOADING, status: true });
    dispatch({ type: RESET_PASSWORD_ERROR, message: "" });

    const url = `/password-reset/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf8" },
      data: JSON.stringify(data),
      url,
    };

    simpleAxios(options)
      .then((response) => {
        const { success, message } = response.data;
        if (success === true) {
          dispatch({ type: RESET_EMAIL_SENT });
          toastr.success("Success", message.toString());
        } else {
          dispatch({ type: RESET_PASSWORD_ERROR, message });
        }
      })
      .catch((error) => {
        const message =
          error.response?.status === 500 ? error.response.statusText : error.response?.data?.message || error.message;
        toastr.error("Error", message.toString());
      })
      .finally(() => {
        dispatch({ type: USER_FORGOT_PASSWORD_LOADING, status: false });
      });
  };
};

export const update_forgot_password = (data, token) => {
  return (dispatch) => {
    dispatch({ type: USER_FORGOT_PASSWORD_LOADING, status: true });

    const url = `/password-reset-done/`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
      url,
    };

    simpleAxios(options)
      .then((response) => {
        // Destructure response data
        const { data: _data, message, success: is_success } = response.data;
        const { id: user_id, is_staff, user_type, is_verified } = _data || {};

        if (is_success && user_id && !isNaN(user_id)) {
          if (!is_staff) {
            // Handle non-staff users
            dispatch({
              type: USERS_LOGIN_SUCCESS,
              user: _data,
            });

            localStorage.setItem("user_type", user_type);
            localStorage.setItem("user", JSON.stringify(_data));
            localStorage.setItem("userId", user_id);
            dispatch(get_user_profile_data(user_id));
            const redirectTo = history.location.state?.prevLocation || "/";
            history.push(redirectTo);
          } else {
            // Handle staff users
            localStorage.setItem("admin_user_type", user_type);
            localStorage.setItem("admin", JSON.stringify(_data));
            localStorage.setItem("adminId", user_id || "");
            dispatch({
              type: ADMIN_LOGIN_SUCCESS,
              admin: _data,
            });
            dispatch(get_dealer_profile_data(user_id));
            dispatch(get_agents_screens(user_id));
          }

        } else {
          // Handle errors
          if (!is_verified) {
            toastr.error("Error", "User is not verified.");
          } else {
            dispatch({ type: RESET_PASSWORD_ERROR, message });
            toastr.error("Error", message || "An error occurred.");
          }
        }
      })
      .catch((err) => {
        // Handle request errors
        const message =
          err.response?.status === 500
            ? err.response.statusText
            : err.response?.data?.message || err.message;
        dispatch({ type: RESET_PASSWORD_ERROR, message });
        toastr.error("Error", message.toString());
      })
      .finally(() => {
        dispatch({ type: USER_FORGOT_PASSWORD_LOADING, status: false });
      });
  };
};


export const user_remain_login = () => {
  return (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {

      dispatch({
        type: USERS_LOGIN_SUCCESS,
        user,
      });
    }
  };
};

export const admin_remain_login = () => {
  return (dispatch) => {
    const admin = JSON.parse(localStorage.getItem("admin"));
    if (admin) {
      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
        admin,
      });
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    cleanLocalStorage();
    expireTokens();
    dispatch({
      type: USERS_LOGOUT,
    });
    dispatch({
      type: REMOVE_ALL_PROFILE_DETAIL,
    });
    history.push("/");
  };
};

export const admin_logout = () => {
  return (dispatch) => {
    cleanLocalStorage();
    dispatch({
      type: ADMIN_LOGOUT,
    });
    window.location("/");
  };
};

export const logout_session = () => {
  return (dispatch) => {
    cleanLocalStorage();
    dispatch({
      type: USERS_LOGOUT,
    });
    dispatch({
      type: REMOVE_ALL_PROFILE_DETAIL,
    });
  };
};

const handleUnauthorizedError = (screen) => {
  const redirectUrl = screen === "admin" ? "/secure/admin-login" : "/";

  const adminKeysToRemove = [
    "admin_user_type",
    "admin",
    "adminSccrens",
    "lastAdminStepPostAppEdit",
    "coAdminApplicantEditPostApp",
    "adminId",
  ];

  const userKeysToRemove = [
    "user",
    "user_type",
    "dealerSccrens",
    "lastStepPostAppEditAdmin",
    "coApplicantEditPostAppAdmin",
    "lastStepDealerPostAppEdit",
    "coApplicantDealerEditPostApp",
    "userId",
  ];

  const keysToRemove = screen === "admin" ? adminKeysToRemove : userKeysToRemove;
  keysToRemove.forEach((key) => localStorage.removeItem(key));

  history.push(redirectUrl);
};

export const refresh_token = (screen) => {
  return (dispatch) => {
    const url = `${API_URL}/api/token/refresh/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf8" },
      url,
      data: {}  // You don't need to send the token in the body because it's in the cookie
    };

    axios(options)
      .then((response) => {
        const { user_type, id } = response.data;
        const storagePrefix = screen === "admin" ? "admin" : "user";

        localStorage.setItem(`${storagePrefix}_user_type`, user_type);
        localStorage.setItem(`${storagePrefix}Id`, id || "");

        if (screen !== "admin") {
          return;
        }
      })
      .catch((err) => {
        handleUnauthorizedError(screen);

        const message = err.response?.status === 500
          ? err.response.statusText
          : err.response?.data?.detail || err.message;

        toastr.error(message);
        dispatch({ type: USERS_LOGIN_FAILURE });
      });
  };
};

const handleResponse = (response, dispatch, shouldRedirect = false) => {
  if (response.data.success) {
    const screens = response.data.data?.[0]?.upuser_id || [];
    const storageKey = response.data.data?.[0]?.dealer_id ? "dealerSccrens" : "adminSccrens";
    localStorage.setItem(storageKey, JSON.stringify(screens));
    dispatch({ type: ADMIN_SCREENS, screens });

    if (shouldRedirect) {
      const redirectTo = history.location.state?.prevLocation || "/admin";
      window.location.href = redirectTo;
    }
  } else {
    localStorage.setItem("adminSccrens", JSON.stringify([]));
    localStorage.setItem("dealerSccrens", JSON.stringify([]));
    dispatch({ type: ADMIN_SCREENS, screens: [] });
  }
};

const _handleError = (err, dispatch) => {
  const message = err.response?.status === 500
    ? err.response.statusText
    : err.response?.data?.message || err.message;

  localStorage.setItem("dealerSccrens", JSON.stringify([]));
  localStorage.setItem("adminSccrens", JSON.stringify([]));
  dispatch({ type: ADMIN_SCREENS, screens: [] });
  toastr.error(message.toString());
};

export const get_agents_screens = (id) => {
  return (dispatch) => {
    const url = `/ap_adminuser/${id}/`;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json; charset=utf8" },
      url,
    };

    simpleAxios(options)
      .then((response) => handleResponse(response, dispatch, true))
      .catch((err) => _handleError(err, dispatch));
  };
};

export const get_agents_screen = (id) => {
  return (dispatch) => {
    const url = `/ap_adminuser/${id}/`;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json; charset=utf8" },
      url,
    };

    simpleAxios(options)
      .then((response) => handleResponse(response, dispatch))
      .catch((err) => _handleError(err, dispatch));
  };
};


export const logout_session_admin = () => {
  return (dispatch) => {
    cleanLocalStorage();
    dispatch({
      type: ADMIN_LOGOUT,
    });
  };
};

export const admin_screens = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_SCREENS,
      screens: data,
    });
  };
};

export const get_user_location_by_ip = () => async (dispatch) => {
  try {
    const res = await simpleAxios.get(`https://extreme-ip-lookup.com/json/?key=${process.env.REACT_APP_IP_LOOKUP_KEY}`);

    const { lat, lon: lng, city, regionName: state } = res.data;
    const data = { lat, lng, city, state };

    dispatch(change_autoComplete_lng_lat(data));
    dispatch(change_map_lng_lat(data));
  } catch (error) {
    console.error('Error fetching user location:', error);
  }
};

export const verify_dealer_telephone = (data, resendOTP = false, setUserId) => {
  return (dispatch) => {
    if (!resendOTP) dispatch({ type: OTP_SEND_START });

    const url = `/verifyuser/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(data),
      url,
    };

    simpleAxios(options)
      .then((response) => {
        if (response.data.success) {
          setUserId(response?.data?.data?.id || "");
          dispatch(send_otp(data, resendOTP));
        } else {
          dispatch({ type: OTP_SEND_FAILURE });
          toastr.error("Error", resendOTP ? "Phone number is not verified" : response.data.message);
        }
      })
      .catch((err) => {
        const message = err.response?.data?.message || err.message;
        dispatch({ type: OTP_SEND_FAILURE });
        toastr.error("Error", message || "Error in verifying the number, please try later!");
      });
  };
};

export const dealer_login_with_phone = (data) => {
  return (dispatch) => {
    const url = `/login_with_otp/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf8" },
      data: JSON.stringify(data),
      url,
    };

    simpleAxios(options)
      .then((response) => {
        const loginData = response?.data?.data || "";

        if (loginData.success && loginData.data && !loginData.data.is_staff) {
          const decoded = jwt_decode(loginData.data.access);

          dispatch({ type: USERS_LOGIN_SUCCESS, user: decoded });

          localStorage.setItem("user_type", loginData.data.user_type);
          localStorage.setItem("user", JSON.stringify(loginData.data));
          localStorage.setItem("userId", loginData.data.id || "");

          if (Number(loginData.data.user_type) === USER_TYPE.DEALER_USER) {
            dispatch(get_dealer_profile_data(loginData.data.id));
          } else {
            dispatch(get_user_profile_data(loginData.data.id));
          }

          const redirectTo = history.location.state?.prevLocation || "/";
          history.push(data.redirect || redirectTo);
        } else if (loginData.success && loginData.data.is_staff) {
          dispatch({ type: USERS_LOGIN_FAILURE });
          toastr.error("Error", "Please Enter Valid Credentials");
        } else {
          dispatch(loginData.success === false && !loginData.data.is_verified ? { type: VERIFY_LOGIN_USER } : { type: USERS_LOGIN_FAILURE });
          toastr.error("Error", loginData.message.toString());
        }
      })
      .catch((error) => {
        const message = error.response?.status === 500 ? error.response.statusText : error.response?.data?.message || error.message;
        dispatch({ type: USERS_LOGIN_FAILURE });
        toastr.error("Error", message.toString());
      });
  };
};

export const dealer_verify_with_phone = (data, userId) => {
  return (dispatch) => {
    dispatch({ type: VERIFY_REQUEST });

    const url = `/verify-otp/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(data),
      url,
    };

    simpleAxios(options)
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: VERIFY_USER_SUCCESS, response: response.data.data });

          const loginData = { id: userId || "", user: true };
          dispatch(dealer_login_with_phone(loginData));
        } else {
          toastr.error("Error", response.data.message.toString());
        }
      })
      .catch((err) => {
        const message = err.response?.data?.message || err.message;
        dispatch({ type: VERIFY_USER_FAILURE });
        toastr.error("Error", message || "Error in verifying OTP, please try later!");
      });
  };
};

