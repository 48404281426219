import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router";
import { history } from "../../_helpers";
import AddressStep from "./components/steps/AddressStep";
import BirthDayStep from "./components/steps/BirthDayStep";
import CongratulationStep from "./components/steps/CongratulationStep";
import { formStateInit } from "./components/constant";
import EmailDetails from "./components/steps/EmailDetails";
import EmploymentStatus from "./components/steps/EmploymentStatus";
import MonthlyBudget from "./components/steps/MonthlyBudget";
import MonthlyIncome from "./components/steps/MonthlyIncome";
import PersonlDetail from "./components/steps/PersonalDetail";
import PrivateSellerStep from "./components/steps/PrivateSellerStep";
import SubTypeOfVehical from "./components/steps/SubTypeOfVehicle";
import TypeOfVehical from "./components/steps/TypeOfVehical";
import WorkPlaceStep from "./components/steps/WorkPlaceStep";
import TostarMessages from "../../components/alertMessages/TostarMessages";
export default function PostApplicationV2() {
  // state
  const totalSteps = 14;
  const [step, setStep] = useState(1);
  const [url, setUrl] = useState("");
  const [formState, setFormState] = useState(formStateInit);

  // query
  const routerState = useLocation()?.state

  // path
  const path = useLocation().pathname;

  // routes
  const routes = [
    {
      Component: TypeOfVehical,
      step: 1,
    },
    {
      Component: SubTypeOfVehical,
      step: 2,
    },
    {
      Component: MonthlyBudget,
      step: 3,
    },
    {
      Component: PrivateSellerStep,
      step: 4,
    },
    {
      Component: EmploymentStatus,
      step: 5,
    },
    {
      Component: MonthlyIncome,
      step: 6,
    },
    {
      Component: WorkPlaceStep,
      step: 7,
    },
    {
      Component: AddressStep,
      step: 8,
    },
    {
      Component: BirthDayStep,
      step: 9,
    },
    {
      Component: PersonlDetail,
      step: 10,
    },
    {
      Component: EmailDetails,
      step: 11,
    },
    {
      Component: CongratulationStep,
      step: 12,
    },
  ];

  // redirecting on mount
  useEffect(() => {
    const pathArr = path.split("/");
    const mainUrl = pathArr.length > 1 ? pathArr[1] : "";
    if (!url) {
      setUrl(mainUrl);
      history.push({
        pathname: `/${mainUrl}/${routerState?.step || 1}`,
        state: routerState
      });
    }

    return () => { };
  }, [url, path, routerState]);
  
  // setting step when path changes
  useEffect(() => {

    const pathArr = path.split("/");
    if (routerState && routerState !== undefined) {
      setFormState({ ...formState, vehicle_type: routerState?.category_name || "", stock: routerState?.stock || "", vehicle: [routerState?.vehicle || ""], user_id: routerState?.user_id || "" })
    }
    if (pathArr.length > 2) {
      const nextStep = pathArr[2];
      const step = routerState?.step || nextStep
      setStep(step);
    }
    return () => { };
  }, [path, formState, routerState]);

  // on form state change
  const onChange = (e) => {
    setFormState((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // on Continue
  const onContinue = (paramStep) => {

    if (paramStep) {
      history.push(
        `/${url}/${paramStep}`
      );
      return;
    }
    history.push(
      `/${url}/${Number(step) + 1}`
    );
  };
  
  return (
    <section className="postAppQuick">
      <div className="formDiv">
        <div className="innerDiv">
          <div className="progressBar">
            <div
              className="filled"
              style={{ width: `${(step / totalSteps) * 100}%` }}
            ></div>
          </div>
          <div>
            <Switch>
              {routes.map(({ Component, step }) => {
                return (
                  <Route
                    path={`/${url}/${step}`}
                    render={(props) => (
                      <Component
                        {...props}
                        formState={formState}
                        setFormState={setFormState}
                        onChange={onChange}
                        onContinue={onContinue}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </div>
        </div>
      </div>
      <TostarMessages />
    </section>
  );
}
