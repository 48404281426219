import axios from 'axios';
import { API_URL } from "../constant"

class NotesService {

  constructor() {
    this.baseUrl = `${API_URL}/zoho-crm/create-note/`;
  }

  async createNote(applicationId, note, direction) {
    const data = { application_id: applicationId, note, direction};
    return axios.post(`${this.baseUrl}`, data);
  }

}

export const notesService = new NotesService();
