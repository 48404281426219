import React from "react";
import MaskedInput from "react-text-mask";

export const TextInput = ({ field, form: { touched, errors }, ...props }) => {
  const isError = touched[field.name] && errors[field.name];
  return (
    <div className={`inputDiv  ${isError && "required"}`} style={props.style}>
      <input
        {...field}
        {...props}
        className={`textInput ${props?.inputClassName}`}
      />
      <span className="invalid-feedback bg-danger text-white w-auto">Required</span>
    </div>
  );
};

// selectinput
export const SelectInput = ({ field, form: { touched, errors }, ...props }) => {
  const isError = touched[field.name] && errors[field.name];

  return (
    <div className={`inputDiv  ${isError && "required"}`} style={props.style}>
      <select
        invalid={touched[field.name] && errors[field.name]}
        {...field}
        {...props}
        className={`textInput select ${props?.inputClassName} ${
          field.value ? "" : "placeholder"
        }`}
      >
        {props?.placeholder && (
          <option value="" disabled hidden selected className="placeholder">
            {props?.placeholder}
          </option>
        )}
        {props?.options?.map((item, index) => (
          <option value={item.value} key={index}>
            {item.label}
          </option>
        ))}
      </select>
      <span className="invalid-feedback bg-danger text-white w-auto">Required</span>
    </div>
  );
};

// masked input Text
export const TextMaskedInput = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const isError = touched[field.name] && errors[field.name];
  return (
    <div className={`inputDiv  ${isError && "required"}`} style={props.style}>
      <MaskedInput
        {...field}
        {...props}
        className={`textInput ${props?.inputClassName}`}
      />
      <span className="invalid-feedback bg-danger text-white w-auto">Required</span>
    </div>
  );
};


export const TelephoneMaskedInput = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const isError = touched[field.name] && errors[field.name];

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove the "+1 " prefix before saving to Formik state
    setFieldValue(field.name, value.replace("+1 ", ""));
  };

  return (
    <div className={`inputDiv ${isError && "required"}`} style={props.style}>
      <MaskedInput
        {...field}
        {...props}
        value={field.value ? `+1 ${field.value}` : ""} // Prepend "+1 " to the value
        onChange={handleChange}
        onBlur={() => setFieldTouched(field.name, true)} // Mark field as touched
        mask={[
          "+",
          "1",
          " ",
          "(",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ")",
          " ",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          "-",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]}
        guide={false}
        className={`textInput ${props?.inputClassName}`}
        placeholder="Phone Number"
      />
      {isError && (
        <span className="invalid-feedback bg-danger text-white w-auto">
          {errors[field.name]}
        </span>
      )}
    </div>
  );
};
