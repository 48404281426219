import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { toastr } from 'react-redux-toastr';
import { notesService } from "../../../_services/notes-service";
import TostarMessages from "../../../../src/components/alertMessages/TostarMessages";
import { fireStoreDB } from "../../../firebase/config";
import AdminHeader from "./ApplicationHeaderAdmin";
import DealerHeader from "../../../dealerAdmin/component/Application/ApplicationHeaderAdmin";
import { formatDistanceToNow } from 'date-fns';
import LoadingOverlay from 'react-loading-overlay';
import { API_URL } from "../../../constant";

function ViewAndSendNotes(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [actionWhileLoading, setActionWhileLoading] = useState("Loading application notes. Please wait...");
  const [notes, setNotes] = useState([]);
  const newNoteRef = useRef(null);
  const shouldShowHeader = props.showHeader;

  // Fetch real-time notes from Firestore based on the application ID
  const fetchRealTimeNotes = useCallback(() => {
    if (!props.buyerAppId) {
      setIsLoading(false); // Stop loading if no buyerAppId is found
      return;
    }

    const appIdStr = String(props.buyerAppId);
    const notesRef = collection(fireStoreDB, "dealer_notes", appIdStr, "notes");
    const notesQuery = query(notesRef, orderBy('created_at', 'desc'));

    const unsubscribeNotes = onSnapshot(
      notesQuery,
      (notesCollection) => {
        const newNotes = notesCollection.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setNotes(newNotes);
        setIsLoading(false);  // Stop loading after fetching completes

        // Call API to reset the unread notes counter
        resetUnreadNotesCounter(props.buyerAppId);

      },
      (error) => {
        toastr.error("Failed to fetch notes");
        setIsLoading(false); // Stop loading if there is an error
      }
    );

    return unsubscribeNotes;
  }, [props.buyerAppId]);

  // Function to reset unread notes counter
  const resetUnreadNotesCounter = async (applicationId) => {
    try {
      const response = await fetch(`${API_URL}/zoho-crm/reset-unread-count/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          application_id: applicationId
        }),
      });

      if (response.ok) {
        await response.json();
      } else {
        await response.json();
      }
    } catch (error) {
      console.error('Error occurred while resetting the unread count', error);
    }
  };

  useEffect(() => {
    let unsubscribeNotes;
    const applicationId = props.buyerAppId;

    if (applicationId) {
      unsubscribeNotes = fetchRealTimeNotes(); // Initialize the listener directly
    } else {
      setIsLoading(false); // Stop loading if no applicationId
    }

    return () => {
      if (unsubscribeNotes) {
        unsubscribeNotes(); // Clean up listener on component unmount
      }
    };
  }, [props.buyerAppId, fetchRealTimeNotes]);

  // Create a new note and send it to Firestore
  const createNote = (message) => {
    const applicationId = props.buyerAppId;

    setActionWhileLoading("Submitting your note..."); // Set message to note submission
    setIsLoading(true); // Start loading when note is being submitted

    const direction = "Inbound";

    notesService.createNote(applicationId, message, direction)
      .then((res) => {
        const data = res.data;
        if (data.success) {
          toastr.success("Success:", data.message || "Note created successfully!");

          // Reset the loading message for loading notes after submission
          setActionWhileLoading("Loading application notes. Please wait...");
        } else {
          toastr.error(data.message || "Failed to create note: " + (data.errors ? data.errors.join(", ") : ""));
        }
        setIsLoading(false); // Stop loading after note submission completes
      })
      .catch((error) => {
        toastr.error(String(error));
        setIsLoading(false); // Stop loading if there's an error
      });
    };

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = newNoteRef.current.value.trim();
    if (!message) {
      toastr.error("Cannot send an empty note");
      return;
    }
    createNote(message);
    newNoteRef.current.value = ""; // Clear the input after submission
  };

  const isDealerAdmin = () => {
    return Number(localStorage.getItem("user_type")) === 2;
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text={actionWhileLoading} // Dynamically update the loading message
    >
      <div className="app-form-content">
        <div className="app-form-content-inner">

          {/* Conditionally show the header only if shouldShowHeader is true */}
          {shouldShowHeader && (
            isDealerAdmin() ? (
              <DealerHeader {...props} />
            ) : (
              <AdminHeader {...props} />
            )
          )}

          <ul className="nav nav-tabs-2 admin-tabs three-items text-left" id="formAppTabs" role="tablist">
            <li className="nav-item">
              <button
                className="nav-link notesIcon-tab active pl-5"
                id="notesIcon-tab"
                onClick={(e) => e.preventDefault()}
              >
                <span className="tabs-text notesIcon">Notes {props.application_category && props.application_category}</span>
              </button>
            </li>
          </ul>

          <form onSubmit={handleSubmit}>
            <div className="tab-content notesMain form-main">
              <div className="clearfix ">
                <label>Notes</label>
                <textarea
                  ref={newNoteRef}
                  name="note"
                  className="form-control notesInput"
                  placeholder="Type your notes here"
                ></textarea>
              </div>
              <div className="clearfix text-right">
                <button className="btn btn-primary sendNoteBtn float-right">
                  Send <img src="/assets/image/arrowRight.svg" alt="" />
                </button>
              </div>
              <ChatMessages notes={notes} dealer_name={props.application_detail.dealer_name} />
            </div>
          </form>
          <TostarMessages />
        </div>
      </div>
    </LoadingOverlay>
  );
}

// Component to render chat messages
const ChatMessages = ({ notes, dealer_name }) => {
  const renderMessage = (note, InOrOutBound, dealer_name) => {

    const noteDate = note.created_at.toDate();
    const relativeTime = formatDistanceToNow(noteDate, { addSuffix: true });
    const absoluteTime = noteDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    const absoluteDate = noteDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return (
      <>
        {InOrOutBound ? (
          <div className={`messageBox RightMessageBox`} key={note.id}>
            <div className="messageHead">
              <div className="dateNote">
                {relativeTime}, {absoluteTime}, {absoluteDate}
              </div>
              <div className="noteName">
                <img src="/assets/image/noteOrange-Icon.svg" alt="Note Icon" /> {note.name}
              </div>
            </div>
            <div className="messageBody">
              <div className="messageBody-inner">{note.note}</div> {/* Display the note content */}
            </div>
          </div>
        ) : (
          <div className={`messageBox LeftMessageBox`} key={note.id}>
            <div className="messageHead">
              <div className="noteName">
                <img src="/assets/image/noteOrange-Icon.svg" alt="Note Icon" /> Finance That
              </div>
              <div className="dateNote">
                {relativeTime}, {absoluteTime}, {absoluteDate}
              </div>
            </div>
            <div className="messageBody">
              <div className="messageBody-inner">{note.note}</div> {/* Display the note content */}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="chatMain">
      {notes.map((note) => renderMessage(note, note.direction === "Inbound", dealer_name))}
    </div>
  );
};

export default ViewAndSendNotes;
