export const application_types = [{ label: 'General', value: 1 }, { label: 'Inventory', value: 2 }, { label: "Manual", value: 3 }, { label: "Lead", value: 4 }]
export const managed_application_types = [{ label: "Manual", value: "3" }, { label: 'Inventory', value: "2" }]
export const private_application_types = [{ label: "Manual", value: "3" }, { label: 'Inventory', value: "2" }, { label: 'General', value: 1 }]
export const application_status = [
    { label: "Conditionally approved", value: "2" },
    { label: "Pre-approved", value: "3" },
    { label: "Approved", value: "5" },
    { label: "Booked", value: "6" },
    { label: "Declined", value: "4" },
    { label: "Withdrawn", value: "7" },
    { label: "Credit Unknown", value: "1" },
    { label: "Conditionally Approved For Special Program", value: "8" },
    { label: "Credit Unverified", value: "11" },
    { label: "Manual", value: "9" },
    { label: "Funded", value: "12" },
]

// query: what ID is give for funded.
export const retail_program_application_status = [
    { label: "Conditionally approved", value: "2" },
    { label: "Booked", value: "6" },
    { label: "Declined", value: "4" },
    { label: "Funded", value: "12" },
]
export const createdBy_options = [{ label: 'Applicant', value: 'applicant' }, { label: 'Dealer', value: 'dealer' },]