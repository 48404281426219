import React, { Component } from 'react';

class SyncToZohoModal extends Component {
  render() {
    return (
      <div className="sync-zoho-modal-container">
        <div
          className="modal fade show"
          id="syncToZohoModal"
          role="dialog"
          aria-labelledby="syncToZohoLabel"
          aria-hidden="true"
          style={{ display: "block" }} // Ensure modal is displayed
        >
          <div className="modal-dialog modal-dialog-centered zohosyn" role="document">
            <div className="modal-content">
              <div className='modal-header'>
                <h2>Sync Dealer with Zoho CRM Accounts:</h2>
              </div>
              <div className="modal-body">
                
                <p>The process consists of two steps: 
                    <div>1. Create a dealer</div>
                    <div>2. Create a dealer contact  </div>
                  </p>

                  <p>If a dealer with the same legal name or a contact with the same email is found, the dealer creation process will fail.
                  </p>
                  <p><strong>Below is the analysis.</strong></p>
                <div className="sync-zoho-response">
                  {this.props.responseData ? (
                    this.props.responseData.error ? (
                      <div className="text-danger">
                        {this.props.responseData.error}
                      </div>
                    ) : (
                      <div>
                        <strong>Dealer Created Successfully!</strong>
                        <p>Details:</p>
                        <pre>{JSON.stringify(this.props.responseData, null, 2)}</pre>
                      </div>
                    )
                  ) : (
                    <div>Loading...</div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.props.onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SyncToZohoModal;
