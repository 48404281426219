import React, { useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { API_URL } from '../../../../src/constant';

/** Add Loader */
import LoadingOverlay from 'react-loading-overlay';

const InvoiceGrid = () => {

    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [filterQuery, setFilterQuery] = useState('');

    /**
     * set loading and action while loading
     */
    const [isLoading, setIsLoading] = useState(false);
    const [actionWhileLoading, setActionWhileLoading] = useState("We are analysing data. Please wait.");

    const columns = [
        { headerName: 'Serial Number', field: 'serial_number' },
        { headerName: 'SKU ID', field: 'sku_id' },
        { headerName: 'Invoice ID', field: 'invoice_id' },
        { headerName: 'Order Number', field: 'order_number' },
        { headerName: 'Dealer ID', field: 'dealer_id' },
        { headerName: 'Dealer Name', field: 'dealer_name' },
        {
            headerName: 'Status',
            field: 'status',
            valueGetter: params => {
                const status = params.data?.status ?? '';
                return `${status}`;
            }
        },
        { 
            headerName: 'Decline Reason', 
            field: 'decline_reason', 
            cellRenderer: params => {
                const decline_reason = params.data?.decline_reason ?? 'N/A';
                return decline_reason.length > 10 ? (
                    <span title={decline_reason}>
                        {decline_reason.substring(0, 10) + '...'}
                    </span>
                ) : decline_reason;
            }
        },
    ];

    const [columnDefs, setColumnDefs] = useState(columns);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const fetchRows = () => {
        if (!gridApi) {
            return;
        }
        const dataSource = {
            getRows: (params) => {
                const page = params.endRow / 10;
                let url = `${API_URL}/dyrect_batches/zoho-invoices/?p_size=10&page=${page}`;
                if (filterQuery != null && filterQuery != '') {
                    url = `${API_URL}/dyrect_batches/zoho-invoices/?p_size=10&page=${page}&search=${filterQuery}`;
                    localStorage.setItem('filterToBeReservedDyrectBatches', filterQuery);
                }
                fetch(url)
                    .then(resp => resp.json())
                    .then(res => {
                        params.successCallback(res.data, res.pagination.count);
                    }).catch(err => {
                        params.successCallback([], 0);
                    });
            }
        };
        gridApi.setDatasource(dataSource);
    }

    useEffect(() => {
        fetchRows();
    }, [columnDefs])

    useEffect(() => {
        if (gridApi) {
            setColumnDefs(columns)
        }
    }, [gridApi, filterQuery]);

    const onFirstDataRendered = event => {
        const { api } = event

        let pageToBeReserved = localStorage.getItem('filterToBeReservedDyrectBatches');
        if (api.paginationGetCurrentPage() == 0 && typeof pageToBeReserved != "undefined") {
            let search = localStorage.getItem('filterToBeReservedDyrectBatches');
            document.querySelector("#filter-text-box").value = search;
            setFilterQuery(search);
            setTimeout(() => {
                api.paginationGoToPage(Number(pageToBeReserved));
                localStorage.removeItem('filterToBeReservedDyrectBatches')
            }, 1000);
        }
    }

    const onFilterTextBoxChanged = useCallback(() => {
        setFilterQuery(document.getElementById('filter-text-box').value)
    }, []);

    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text={actionWhileLoading}
        >
            <div>
                <div className="Pending-ListHead">
                    <h1>Serial Number Batch Creation Status</h1>
                </div>
                <div className="ag-theme-alpine ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                    <div className="Altable-Container">
                        <div className="AlContainer" style={{ padding: "20px 0px 0px" }}>
                            <div className="ActiveList-Left pl-3">
                                <input
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Filter by Serial Number..."
                                    onInput={onFilterTextBoxChanged}
                                />
                            </div>
                        </div>
                        <div className="dealer-dtable table-responsive applyNowList">
                            <AgGridReact
                                rowData={rowData}
                                columnDefs={columnDefs}
                                onGridReady={onGridReady}
                                cacheBlockSize={10}
                                rowHeight={55}
                                domLayout="autoHeight"
                                rowModelType={'infinite'}
                                pagination={true}
                                paginationPageSize={10}
                                defaultColDef={{ flex: 1 }}
                                onFirstDataRendered={onFirstDataRendered}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
};

export default InvoiceGrid;
