import { Component } from "react";

export class WithSubmit extends Component {
  state = {
    submitButtonVisible: true,
  };

  enableSubmit() {
    this.setState({ submitButtonVisible: true });
  }

  disableSubmit() {
    this.setState({ submitButtonVisible: false });
  }
}
