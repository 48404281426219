import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

export default function ApplicationHeaderAdmin(props) {

  function applicationType(apptypeId) {
    const types = {
      1: "General",
      2: "Inventory",
      3: "Manual",
      4: "Lead"
    }
    return types[apptypeId] || ""
  }

  function applicationCategory(appCatId) {
    const types = {
      1: "Unmanaged",
      2: "Managed",
      3: "Private Sale",
      4: "In House"
    }
    return types[appCatId] || ""
  }

  function formatPhoneNumber(telephone) {
    telephone = telephone?.replace("+1",).trim() || ""
    var cleaned = ('' + telephone).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  function cleanPhoneNumber(phoneNumber) {
    return (phoneNumber || "").length === 11
      ? (phoneNumber?.slice(-10) ?? "")
      : (phoneNumber?.replace("+1", "").trim() ?? "");
  }

  // - Use || (logical OR) if you want to include all falsy values such as 0, "", NaN, and false, along with null and undefined, in your default value assignment.
  // - Use ?? (nullish coalescing operator) if you specifically want to handle only null or undefined values, excluding other falsy values.

  const firstName = props.applicantFirstName || "";
  const lastName = props.applicantLastName || "";

  const name = `${firstName} ${lastName}`;
  const email = props.applicantEmailByDealer || (props.applicantEmail || "");
  const telephone = props.applicantTelephone ?? "";
  const user = props.application_detail?.user;
  const dealer_name = props.application_detail.dealer_name;
  const dealer_email = props.application_detail.dealer_email

  const dealer_phone = props.application_detail.dealer_phone;
  let dealerPhoneNumber = cleanPhoneNumber(dealer_phone);

  const seller = props?.application_detail?.seller
  let sellerTelephone = seller?.telephone ?? "";
  sellerTelephone = cleanPhoneNumber(sellerTelephone);

  let userTelephone = user?.phone_number ?? "";
  userTelephone = cleanPhoneNumber(userTelephone);

  const isLoginUser = localStorage.getItem('adminId') !== user?.id

  const displayName = props.appType === "UnManaged"
    ? user?.last_name || ''
    : props.appType === 'Private'
      ? `${seller?.first_name || ''} ${seller?.last_name || ''}`
      : dealer_name || '';

  const emailToDisplay =
    props.appType === 'UnManaged'
      ? user?.email
      : props.appType === 'Private'
        ? seller?.email || ''
        : dealer_email || '';
  
  return (
    <div className="applicationHeaderAdmin">
      <div className="applicantSec">
        <div className="typeTag d-none d-md-flex">
          <span>Applicant</span>
        </div>
        <div className="innerWrapper">
          <div className="contantSec">

            <div className="headFirst">

              {/* applicant name */}
              <h2>{name}</h2>

              <div className="applicationRighTag">

                {/* vehicle type */}
                {(props.firstTypeOfVehicle || props.interestedVehicleType) && (
                  <div className="tag">
                    {props.firstTypeOfVehicle || props.interestedVehicleType}
                  </div>
                )}

                {/* application ID */}
                {props.buyerAppId && (<div className="tag">ID: {props.buyerAppId}</div>)}

                {/* application type */}
                {(props.application_type) && (
                  <div className="tag">
                    {applicationType(props.application_type)}
                  </div>
                )}

                {/* application category */}
                {(props.application_category) && (
                  <div className="tag">
                    {applicationCategory(props.application_category)}
                  </div>
                )}

                {/* Assign agent */}
                <div className="agentBtnRight">
                  {props.selectedAgent &&
                    Object.keys(props.selectedAgent).length > 0 ? (
                    <Link className="float-right" to={`${props.url}/assign-agent`}>
                      <div className="agentSec">
                        <i className="bi bi-person-plus" style={{ fontStyle: 'normal' }}></i><span style={{ padding: '0px 10px' }}>
                          {`${props.selectedAgent &&
                            Object.keys(props.selectedAgent).length > 0
                            ? props.selectedAgent.label
                            : ""
                            } `}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <Link className="agentlinkstyle float-right" to={props.addAppLication ? "#" : `${props.url}/assign-agent`}>
                      {props.selectedAgent &&
                        Object.keys(props.selectedAgent).length > 0
                        ? props.selectedAgent.label
                        : <span style={{ padding: '0px 8px 0px 25px' }}>Assign Rep</span>
                      }
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="headSecond">

              {/* Telephone + Email + SIN (Social Insurance Number) + Created At */}

              {telephone && (
                <div className="item">
                  <img
                    src="/assets/image/applicationPhoneIcon.svg"
                    alt="phone"
                  ></img>
                  <span> +1 {formatPhoneNumber(telephone) || telephone}</span>
                </div>
              )}

              {email && (<div className="item">
                <img
                  src="/assets/image/applicationMessageIcon.svg"
                  alt="email"
                ></img>
                <span>{email}</span>
              </div>)}

              {props.applicantSin && (
                <div className="item">
                  <img
                    src="/assets/image/applicationSinIcon.svg"
                    alt="sin"
                  ></img>
                  <span> {props.applicantSin}</span>
                </div>
              )}

              {props.created_at && (
                <div className="date">
                  <div className="circle">
                    <img
                      src="/assets/image/calenderApplicantion.svg"
                      alt="calender"
                    />
                  </div>
                  <span>
                    {props.created_at !== ""
                      ? moment(props.created_at).format("ll")
                      : ""}
                  </span>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>

      {/* 
        Application Category
        1 = unmanaged
        2 = managed
        3 = private sales
        4 = in house
        
        Application Type
        1 = general
        2 = inventory
        3 = manual
        4 = lead(complete)        

        query: are we using category or type.

      */}
      {(isLoginUser && props.application_category !== 4) && (
        <div className="dealerSec">
          <div className="typeTag d-none d-md-flex dealer__img">Dealer</div>
          <div className="innerWrapper">

            {/* Dealer name */}
            <div className="leftSec">
              <p>{props.appType === "UnManaged" ? "Assigned" : props.appType === 'Private' ? "Seller name" : "Dealer name"}</p>
              <h2>{displayName.length > 1 ? displayName : "Dealer Name Unavailable"}</h2>
            </div>

            {/* Dealer Information { Phone + Email } */}
            <div className="rightDealerSec">
              <p className="mr-2 mb-2">{props.appType !== "Private" ? "Dealer Information" : "Seller Information"}</p>
              {user?.phone_number && (
                <div className="flexCenter float-left mr-5">
                  <img
                    src="/assets/image/applicationPhoneIcon.svg"
                    alt="phone"
                  ></img>
                  <span> +1 {formatPhoneNumber(dealerPhoneNumber) || formatPhoneNumber(sellerTelephone) || formatPhoneNumber(userTelephone)}</span>
                </div>
              )}

              <div className="flexCenter float-left">
                <img
                  src="/assets/image/applicationMessageIcon.svg"
                  alt="Email"
                ></img>
                <span>{emailToDisplay.length > 1 ? emailToDisplay : "Email Unavailable"}</span>
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
}
