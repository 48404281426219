import {
  START_POST_APPLICATION,
  POST_APPLICATION_SUCCESS,
  END_POST_APPLICATION,
  GET_POST_APPLICATIONS,
  GET_DMS_POST_APPLICATIONS,
  GET_STOCK_RECORD_BY_ID,
  REMOVE_ALL_POST_APP_STATE,
  RESUBMIT_POST_APP,
  SET_RESUBMIT_TRUE,
  CONFIRM_POST_APPLICATION,
  LOADING_STOCK_BY_ID,
  LOADING,
  DISCARD_POST_STATE,
  PUT_UPDATE_SIN_REQUEST,
} from "../_constants/constants";
import { toastr } from "react-redux-toastr";
import axios from "../_helpers/axiosInterceptors";
function creditScore(credit700score) {
  let credit = `${credit700score}`;
  let start = credit.indexOf("SRC");
  let end = credit.indexOf("HEIGHT");
  return credit.slice(start + 12, end - 2);
}
function titleCase(str) {
  str = str.replace("_", " ");
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
function getProvince(str) {
  const isLargeNumber = (element) => element.value == str;
  const array1 = [
    { value: "AB", label: "Alberta" },
    { value: "BC", label: "British Columbia" },
    { value: "MB", label: "Manitoba" },
    { value: "NB", label: "New Brunswick" },
    { value: "NL", label: "Newfoundland and Labrador" },
    { value: "NS", label: "Nova Scotia" },
    { value: "ON", label: "Ontario" },
    { value: "PE", label: "Prince Edward Island" },
    { value: "QC", label: "Quebec" },
    { value: "SK", label: "Saskatchewan" },
    { value: "NT", label: "Northwest Territories" },
    { value: "NU", label: "Nunavut" },
    { value: "YT", label: "Yukon" },
  ];
  let i = array1.findIndex(isLargeNumber);
  return array1[i]?.label;
}
export const save_post_application = (data, callback) => {
  return (dispatch) => {
    dispatch({
      type: START_POST_APPLICATION,
    });

    const url = `/application/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf8" },
      data: { ...data },
      url,
    };
    axios(options)
      .then(async (response) => {
        //
        if (response.data.success == true) {
          dispatch({
            type: POST_APPLICATION_SUCCESS,
            response: response.data.response_type,
            message: response.data.message,
            amount: response.data.amount,
            app_id: response.data.data
              ? response.data.data.id
                ? response.data.data.id
                : ""
              : "",
            jumio_url: response.data.data.jumio_url,
            jumio_reference: response.data.data.jumio_reference,
            credit_score: response.data.data.score,
          });

          callback && callback();
          toastr.success("Success", "Post App Created");
        }
      })
      .catch((err) => {
        dispatch({
          type: END_POST_APPLICATION,
        });
        const message = err?.response?.message?.postal_code
          ? err?.response?.message?.postal_code
          : err.response !== undefined && err.response !== null
          ? err.response.data.message !== undefined
            ? err.response.data.message
            : err.message
          : err.message;
        toastr.error(
          "Error",
          message?.postal_code ? "Invalid Postal Code" : message.toString()
        );
      });
  };
};

export const update_post_application = (data) => {
  return (dispatch) => {
    dispatch({
      type: START_POST_APPLICATION,
    });
    const url = `/application/${data.id}/`;
    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json; charset=utf8" },
      data: { ...data },
      url,
    };
    axios(options)
      .then(async (response) => {
        //
        if (response.data.success == true) {
          dispatch({
            type: POST_APPLICATION_SUCCESS,
            response: response.data.response_type,
            message: response.data.message,
            amount: response.data.amount,
            app_id: response.data.data
              ? response.data.data.id
                ? response.data.data.id
                : ""
              : "",
            jumio_url: response.data.data.jumio_url,
            jumio_reference: response.data.data.jumio_reference,
            credit_score: response.data.data.score,
          });
          toastr.success("Success", "Post App Created");
        }
      })
      .catch((err) => {
        dispatch({
          type: END_POST_APPLICATION,
        });
        const message =
          err.response !== undefined && err.response !== null
            ? err.response.data.message !== undefined
              ? err.response.data.message
              : err.message
            : err.message;
        toastr.error("Error", message.toString());
      });
  };
};

export const get_post_applications = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_POST_APPLICATIONS,
      response: [],
    });
    // http://127.0.0.1:8000/application?user_id=my_applications
    // const url = `/application/`
    // http://127.0.0.1:8000/application?user_id=my_applications
    const url = `/application/`;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json; charset=utf8" },
      params: data,
      // params: {
      //   user_id: "my_applications",
      // },
      // params: data,
      url,
    };
    axios(options)
      .then((response) => {

        if (response.data.success == true) {
          dispatch({
            type: GET_POST_APPLICATIONS,
            response: response.data.data,
          });
        }
      })
      .catch((err) => {
        const message =
          err.response !== undefined && err.response !== null
            ? err.response.data.message !== undefined
              ? err.response.data.message
              : err.message
            : err.message;
        toastr.error("Error", message.toString());
      });
  };
};
export const get_dms_post_applications = () => {
  return (dispatch) => {
    dispatch({
      type: LOADING,
      response: true,
    });
    var data = {};
    if (Number(localStorage.getItem("user_type")) === 1) {
      data = {
        user_id: "my_applications",
      };
    } else {
      data = {
        user_id: "apps_on_my_listings",
      };
    }

    const url = `/application/`;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json; charset=utf8" },
      params: data,
      // params: {
      //   user_id: "my_applications",
      // },
      // params: data,
      url,
    };
    axios(options)
      .then((response) => {
        if (response.data.success == true) {
          let obj = {
            General: response?.data?.data,
            Inventory: [],
            Manual: [],
            Approved: [],
            Declined: [],
            Withdrawn: [],
            Deleted: [],
          };
          response?.data?.data?.map((item) => {
            if (item.application_status == "1") {
              obj?.Inventory?.push(item);
            } else if (item.application_status == "2") {
              obj?.Manual?.push(item);
            } else if (item.application_status == "3") {
              obj?.Approved?.push(item);
            } else if (item.application_status == "4") {
              obj?.Declined?.push(item);
            } else if (item.application_status == "5") {
              obj?.Withdrawn?.push(item);
            } else if (item.application_status == "6") {
              obj?.Deleted?.push(item);
            }
          });
          dispatch({
            type: GET_DMS_POST_APPLICATIONS,
            response: obj,
          });
        }
        dispatch({
          type: LOADING,
          response: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: LOADING,
          response: false,
        });
        const message =
          err.response !== undefined && err.response !== null
            ? err.response.data.message !== undefined
              ? err.response.data.message
              : err.message
            : err.message;
        toastr.error("Error", message.toString());
      });
  };
};
export const delete_single_row = (id) => {
  return (dispatch) => {
    dispatch({
      type: LOADING,
      response: true,
    });
    const url = `/del-application/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf8" },
      data: { id: [id] },
      url,
    };
    axios(options)
      .then((response) => {
        if (response.data.success == true) {
          const message =
            response.data.message !== undefined &&
            response.data.message !== null
              ? response.data.message
              : "Deleted Successfully";
          toastr.success("Success", message.toString());
          dispatch(get_dms_post_applications());
          dispatch({
            type: LOADING,
            response: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: LOADING,
          response: false,
        });
        const message =
          err.response !== undefined && err.response !== null
            ? err.response.data.message !== undefined
              ? err.response.data.message
              : err.message
            : err.message;
        toastr.error("Error", message.toString());
      });
  };
};
export const delete_multi_row = (ids) => {
  return (dispatch) => {
    dispatch({
      type: LOADING,
      status: true,
    });
    const url = `/del-application/`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf8" },
      data: { id: ids },
      url,
    };
    axios(options)
      .then((response) => {
        if (response.data.success == true) {
          dispatch(get_dms_post_applications());
          const message =
            response.data.message !== undefined &&
            response.data.message !== null
              ? response.data.message
              : "Deleted Successfully";
          toastr.success("Success", message.toString());
        }
        dispatch({
          type: LOADING,
          status: false,
        });
      })

      .catch((err) => {
        dispatch({
          type: LOADING,
          status: false,
        });
        const message =
          err.response !== undefined && err.response !== null
            ? err.response.data.message !== undefined
              ? err.response.data.message
              : err.message
            : err.message;
        toastr.error("Error", message.toString());
      });
  };
};

export const get_stock_by_id = (id) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_STOCK_BY_ID,
      status: true,
    });
    dispatch({
      type: GET_STOCK_RECORD_BY_ID,
      response: [],
      message: "",
    });
    // const url = `/ads/${id}/`;
    const url = `/listing/${id}/`;
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json; charset=utf8" },
      url,
    };
    axios(options)
      .then((response) => {

        if (response.data.success == true) {
          dispatch({
            type: GET_STOCK_RECORD_BY_ID,
            response: [response.data.data],
            message: "Vehicle Found!",
          });
        }
        dispatch({
          type: LOADING_STOCK_BY_ID,
          status: false,
        });
      })
      .catch((err) => {
        const message =
          err.response !== undefined && err.response !== null
            ? err.response.data.message !== undefined
              ? err.response.data.message
              : err.message
            : err.message;
        // toastr.error('Error', message.toString())
        dispatch({
          type: GET_STOCK_RECORD_BY_ID,
          response: [],
          message: "We couldn’t find this stock number in our inventory",
        });
        dispatch({
          type: LOADING_STOCK_BY_ID,
          status: false,
        });
      });
  };
};
export const reSubmit_post_application = () => {
  return (dispatch) => {
    dispatch({
      type: RESUBMIT_POST_APP,
    });
  };
};

export const Update_Sin_Number = ({ id, sin }) => {
  return (dispatch) => {
    dispatch({
      type: PUT_UPDATE_SIN_REQUEST,
    });
    // dispatch({
    //   type: PUT_UPDATE_SIN_SUCCESS,
    // });
    // dispatch({
    //   type: PUT_UPDATE_SIN_FAILED,
    // });
    const url = `sin-verify/${id}/`;
    const options = {
      method: "PUT",
      headers: { "Content-Type": "application/json; charset=utf8" },
      data: { sin },
      url,
    };
    axios(options)
      .then(async (response) => {
        //
        if (response.data.success == true) {
          dispatch({
            type: POST_APPLICATION_SUCCESS,
            response: response.data.response_type,
            message: response.data.message,
            amount: response.data.amount,
            app_id: response.data.data
              ? response.data.data.id
                ? response.data.data.id
                : ""
              : "",
            jumio_url: response.data.data.jumio_url,
            jumio_reference: response.data.data.jumio_reference,
            credit_score: response.data.data.score,
          });

          toastr.success("Success", "Post App Created");
        }
      })
      .catch((err) => {
        dispatch({
          type: END_POST_APPLICATION,
        });
        const message =
          err.response !== undefined && err.response !== null
            ? err.response.data.message !== undefined
              ? err.response.data.message
              : err.message
            : err.message;
        toastr.error("Error", message.toString());
      });
  };
};
export const set_resubmit_application_true = () => {
  return (dispatch) => {
    dispatch({
      type: SET_RESUBMIT_TRUE,
    });
  };
};
export const confirm_post_application = () => {
  return (dispatch) => {
    dispatch({
      type: CONFIRM_POST_APPLICATION,
    });
  };
};
export const remove_all_post_state = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ALL_POST_APP_STATE,
    });
  };
};
export const discard_post_state = () => {
  return (dispatch) => {
    return dispatch({
      type: DISCARD_POST_STATE,
    });
  };
};
