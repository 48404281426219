import axios from "axios";
import { API_URL } from '../constant';
import { history } from './history';
import * as ACTIONS from '../actions/authActions';
import Store from './store';

// Create an axios instance with the base URL
const authInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true // Ensure credentials (cookies) are sent with requests
});

// Request interceptor: Remove manual token management
authInstance.interceptors.request.use(
    config => {
        // No need to manually add the Authorization header since we rely on HttpOnly cookies
        return config;
    },
    error => Promise.reject(error)
);

// Response interceptor to handle token refresh logic
authInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        // If the response status is 403 and the request hasn't been retried yet, try to refresh the token
        if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Attempt to refresh the token via the refresh endpoint
                const response = await axios.post(`${API_URL}/token/refresh/`, {}, { withCredentials: true });

                if (response?.status === 201 || response?.status === 200) {
                    // Assuming the backend sets the new access token in the HttpOnly cookie
                    // No need to manually get or set the token; just retry the original request
                    return authInstance(originalRequest); // Retry the original request
                }
            } catch (err) {
                // If refreshing the token fails, log out the user and redirect to the login page
                if (Number(localStorage.getItem('user_type')) !== 2) {
                    Store.dispatch(ACTIONS.logout_session_admin());
                    history.push('/secure/admin-login');
                }
                return Promise.reject(err);
            }
        }

        // If the error is not handled by the refresh logic, reject it
        return Promise.reject(error);
    }
);

export default authInstance;
