import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import LeadGeneratorComponent from '../../component/leadGenerator/LeadGeneratorComponent';

class LeadGenerator extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Settings</title>
                    <meta name="description" content="" />
                </Helmet>
                <LeadGeneratorComponent />
            </React.Fragment>
        )
    }
    
}

export default LeadGenerator