import React from 'react';

const extractDigitsAndLastTen = (phoneNumber) => {
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    const lastTenDigits = digitsOnly.slice(-10);
    return lastTenDigits;
}

const formatPhoneNumber = (phoneNumber) => {
    const formattedNumber = `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6)}`;
    return formattedNumber;
}

const DealerDetail = ({ dealerName, dealerContactPhone, dealerContactEmail,dealerContact }) => {
    const formattedNumber = formatPhoneNumber(extractDigitsAndLastTen(dealerContactPhone));
    return (
        <div className='purpleBoxBottom'>
            <div className='dealer-detailHeading'>
                Dealer Details
            </div>
            <div className='bottomContentArea'>
                <div className='deatilInner'>
                    <label>Dealer Name</label>
                    <h6>{dealerName}</h6>
                    <label>Primary Contact</label>
                    <h6>{dealerContact}</h6>
                    <label>Primary Contact Phone</label>
                    <h6>+1 {formattedNumber}</h6>
                    <label>Primary Contact Email</label>
                    <h6>{dealerContactEmail}</h6>
                </div>
            </div>
        </div>
    );
};

export default DealerDetail;