import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

// Define the formatString function
function formatString(str) {
  return str.replace(/(_\w)|(\bId$)/g, function (match) {
    return match.charAt(1) ? ' ' + match.charAt(1).toUpperCase() + match.slice(2) : '';
  }).replace(/^./, function (match) {
    return match.toUpperCase();
  });
}

function PushToFrontlineModal({ isOpen, onClose, applicationId, modalMessage, isApplicationPushed }) {
  const editUrl = `/admin/application/pending/${applicationId}`;

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isApplicationPushed ? "Application successfully pushed:" : "Application push failed due to errors:"}
          {isApplicationPushed ? (
            <>
              <p style={{ fontSize: '14px', marginBottom: '0px' }}>Here are the details of the response from Frontline.</p>
            </>
          ) : (
            <>
              <p style={{ fontSize: '14px', marginBottom: '0px' }}>Fix application errors and attempt 'Push To Frontline' again.</p>
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.keys(modalMessage).map((key, index) => (
          <p key={index}><strong>{`${formatString(key)}`}:</strong><span className='errorFrontModal' style={{ marginLeft: '10px' }}> {modalMessage[key].join(', ')}</span></p>
        ))}
      </Modal.Body>
      <Modal.Footer className='New-ModalBtn' style={{ margin: '0px' }}>
        {!isApplicationPushed ? (
        <button className='btnBlank' style={{ width: '180px' }}>
          <Link className='detaillinkbtn editFrontModal' to={editUrl} style={{ height: '45px', width: '160px', fontSize: '16px', display: 'inline-block', borderRadius: '10px', lineHeight: '35px' }}>
            Edit Application
          </Link>
        </button>
        ):""}
        <Button variant="secondary" onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PushToFrontlineModal;
